<template>
    <div id="filter" class="row" style="padding-bottom: 1%;">
        <h2 class="text-start">Opći izvještaj žrtava </h2>

        <div class="col-md-1">
            <br>
            <label for="tip" class="form-label">Tip žrtve</label>
            <select class="form-control" id="tip" v-model="selectedTip">
                <option value="">Sve</option>
                <option v-for="status in tipList" :key="status.value" :value="status.value">{{
                    status.label }}</option>
            </select>
        </div>

        <div class="col-md-2">
            <br>
            <label for="DatumKreiranja" class="form-label">Datum kreiranja od</label>
            <input type="date" class="form-control" id="DatumKreiranja" v-model="this.DatumKreiranja">
        </div>

        <div class="col-md-2">
            <br>
            <label for="DatumKreiranjaDo" class="form-label">Datum kreiranja do</label>
            <input type="date" class="form-control" id="DatumKreiranjaDo" v-model="this.DatumKreiranjaDo">
        </div>

        <div class="col-md-2">
            <br>
            <label for="MentalnoOboljenje" class="form-label">Mentalno oboljenje žrtve</label>
            <select class="form-control" id="MentalnoOboljenje" v-model="selectedMentalnoOboljenje">
                <option value="">Sve</option>
                <option v-for="status in this.MentalnoOboljenjepList" :key="status.value" :value="status.value">{{
                    status.label }}</option>
            </select>
        </div>

        <div class="col-md-2">
            <label for="PociniocMentalno" class="form-label">Mentalno oboljenje pocinioca</label>
            <select class="form-control" id="PociniocMentalno" v-model="selectedPociniocMentalno">
                <option value="">Sve</option>
                <option v-for="status in this.MentalnoOboljenjepList" :key="status.value" :value="status.value">{{
                    status.label }}</option>
            </select>
        </div>

        <div class="col-md-2">
            <br>
            <label for="Vrsta_mjere" class="form-label">Vrsta mjere</label>
            <select class="form-control" id="Vrsta_mjere" v-model="selectedVrstaMjere">
                <option value="">Sve</option>
                <option v-for="status in vrstaMjereList" :key="status.value" :value="status.value">
                    {{ status.label }}</option>
            </select>
        </div>
        <div class="col-md-1" style="padding-top: 2%;">
            <br>
            <button @click="printData" type="button" class="btn btn-secondary" id="print"><i class="fa-solid fa-print"></i>
                Print</button>
        </div>
        <div class="row">
            <br>
            <div class="col-md-2">
                <br>
                <label for="ZlostavljniKaoDijete" class="form-label">Zrtva zlostavljan</label>
                <select class="form-control" id="ZlostavljniKaoDijete" v-model="selectedZlostavljniKaoDijete">
                    <option value="">Sve</option>
                    <option v-for="status in daNeList" :key="status.value" :value="status.value">{{
                        status.label }}</option>
                </select>
            </div>
            <div class="col-md-2">
                <br>
                <label for="ZlostavljaniKaoDijetePocinioc" class="form-label">Pocinioc zlostavljan</label>
                <select class="form-control" id="ZlostavljaniKaoDijetePocinioc"
                    v-model="selectedZlostavljaniKaoDijetePocinioc">
                    <option value="">Sve</option>
                    <option v-for="status in daNeList" :key="status.value" :value="status.value">{{
                        status.label }}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="OpojnaSredstva" class="form-label">Zrtva koristi opjna sredstva</label>
                <select class="form-control" id="OpojnaSredstva" v-model="selectedOpojnaSredstva">
                    <option value="">Sve</option>
                    <option v-for="status in daNeList" :key="status.value" :value="status.value">{{
                        status.label }}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="OpojnaSredstvaPocinioc" class="form-label">Pocinioc koristi opojna sredstva</label>
                <select class="form-control" id="OpojnaSredstvaPocinioc" v-model="selectedOpojnaSredstvaPocinioc">
                    <option value="">Sve</option>
                    <option v-for="status in daNeList" :key="status.value" :value="status.value">{{
                        status.label }}</option>
                </select>
            </div>
        </div>
    </div>
    <br>
    <br>

    <div>
        <hr id="divider" class="hr">

        <table id="tablePrint" class="table table-striped">
            <thead>
                <tr>
                    <th style="width: 10%"> Tip</th>

                    <th style="width: 10%">Ime i prezime</th>
                    <th style="width: 10%">Adresa</th>
                    <th style="width: 10%">Mentalno oboljenje žrtve</th>
                    <th style="width: 10%">Mentalno oboljenje pocinioca</th>
                    <th style="width: 10%">Zastitna mjera </th>
                    <th style="width: 10%">Zrtva zlostavljana kao dijete </th>
                    <th style="width: 10%">Pocinioc zlostavljan kao dijete </th>
                    <th style="width: 10%">Zrtva korsti opojna sredstva</th>
                    <th style="width: 10%">Pocinioc korsti opojna sredstva</th>
                    <th style="width: 10%">Datun kreiranja</th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="item in filteredReportData" :key="item.id">
                    <td style="width: 10%  ">{{ item.Tip }}</td>
                    <td style="width: 10%">{{ item.Ime + ' ' + item.Prezime }}</td>
                    <td style="width: 10%">{{ item.Adresa }}</td>
                    <td style="width: 10%">{{ getLabelById(item.MentalnoOboljenje) }}</td>
                    <td style="width: 10%">{{ getLabelById(item.PociniocMentalno) }}</td>
                    <td style="width: 10%">{{ getLabelById(item.VrstaMjere) }}</td>
                    <td style="width: 10%">{{ getLabelById(item.ZlostavljniKaoDijete) }}</td>
                    <td style="width: 10%">{{ getLabelById(item.ZlostavljaniKaoDijetePocinioc) }}</td>
                    <td style="width: 10%">{{ getLabelById(item.OpojnaSredstva) }}</td>
                    <td style="width: 10%">{{ getLabelById(item.OpojnaSredstvaPocinioc) }}</td>
                    <td style="width: 10%">{{ formatDate(item.CreatedAt) }}</td>
                </tr>
                <tr v-if="filteredReportData.length === 0">
                    <td colspan="12" class="text-center">Nema podataka</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import axios from 'axios';
import config from '../../config';

const apiUrl = `${config.backendUrl}/slucaj/report`;
const apiUrlLookupList = `${config.backendUrl}/lookup/list`;

export default {
    data() {
        return {
            reportData: [],
            tipFilter: '',

            DatumKreiranja: null,
            DatumKreiranjaDo: null,
            lookupData: [],
            filteredData: [],
            Tip: '',
            tipList: [{ value: 'Djevojka', label: 'Djevojka' }, { value: 'Žena', label: 'Žena' }],
            daNeList: [{ value: 0, label: 'Ne' }, { value: 1, label: 'Da' }],
            MentalnoOboljenje: '',
            PociniocMentalno: '',
            MentalnoOboljenjepList: [],
            VrstaMjere: '',
            vrstaMjereList: [],
            ZlostavljniKaoDijete: '',
            ZlostavljaniKaoDijetePocinioc: '',
            OpojnaSredstva: '',
            OpojnaSredstvaPocinioc: '',

        };
    },
    mounted() {
        const token = localStorage.getItem('jwt');
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        axios.get(apiUrl, { headers: headers })
            .then(response => {
                this.reportData = response.data;
            })
            .catch(error => {
                console.error(error);
            });

        axios.get(apiUrlLookupList, { headers })
            .then(response => {
                this.lookupData = response.data.map(item => ({ label: item.value, value: item.id }));

                this.MentalnoOboljenjepList = response.data.filter(item => item.name === 'mentalno_oboljenje').map(item => ({ label: item.value, value: item.id }));
                this.vrstaMjereList = response.data.filter(item => item.name === 'vrsta_mjere').map(item => ({ label: item.value, value: item.id }));

            }).catch(error => {
                console.error('Error:', error);
            });
    },
    methods: {
        getLabelById(id) {
            const lookupItem = this.lookupData.find(item => item.value === id);
            return lookupItem ? lookupItem.label : '-';
        },
        printData() {
            window.print();
        },
        formatDate(date) {
            if (date) {
                let parsedDate = new Date(date);
                if (!isNaN(parsedDate)) {
                    return parsedDate.toISOString().slice(0, 10);
                }
            }
            return ''; // or return a placeholder string like 'N/A' or 'Unknown'
        },
    },

    computed: {
        filteredReportData() {
            return this.reportData.filter(item => {
                const tipMatch = !this.selectedTip || item.Tip === this.selectedTip;
                const itemDate = item.CreatedAt ? new Date(item.CreatedAt).setHours(0, 0, 0, 0) : null;
                // todooooo check this works on datum kreiranja
                const selectedDate = this.DatumKreiranja ? new Date(this.DatumKreiranja).setHours(0, 0, 0, 0) : null;
                const selectedDateDo = this.DatumKreiranjaDo ? new Date(this.DatumKreiranjaDo).setHours(0, 0, 0, 0) : null;
                const dateMatch = !this.DatumKreiranja || (itemDate && itemDate >= selectedDate);
                const dateMatchDo = !this.DatumKreiranjaDo || (itemDate && itemDate <= selectedDateDo);

                const mentalnoOboljenjeMatch = !this.selectedMentalnoOboljenje || item.MentalnoOboljenje === this.selectedMentalnoOboljenje;
                const vrstaMjereMatch = !this.selectedVrstaMjere || item.VrstaMjere === this.selectedVrstaMjere;
                const ZlostavljaniKaoDijetePociniocMatch = (this.selectedZlostavljaniKaoDijetePocinioc == null) || item.ZlostavljaniKaoDijetePocinioc === this.selectedZlostavljaniKaoDijetePocinioc;
                const ZlostavljniKaoDijeteMatch = (this.selectedZlostavljniKaoDijete == null) || item.ZlostavljniKaoDijete === this.selectedZlostavljniKaoDijete;
                const PociniocMentalnoMatch = !this.selectedPociniocMentalno || item.PociniocMentalno === this.selectedPociniocMentalno;
                const OpojnaSredstvaMatch = (this.selectedOpojnaSredstva == null) || item.OpojnaSredstva === this.selectedOpojnaSredstva;
                const OpojnaSredstvaPociniocMatch = (this.selectedOpojnaSredstvaPocinioc == null) || item.OpojnaSredstvaPocinioc === this.selectedOpojnaSredstvaPocinioc;
                // const dateMatch = !this.DatumKreiranja || itemDate === selectedDate;

                return OpojnaSredstvaMatch && OpojnaSredstvaPociniocMatch && ZlostavljniKaoDijeteMatch && tipMatch && dateMatch && dateMatchDo && mentalnoOboljenjeMatch && PociniocMentalnoMatch && vrstaMjereMatch && ZlostavljaniKaoDijetePociniocMatch;
            });
        },

        selectedOpojnaSredstva: {
            get() {
                return this.daNeList.find(item => item.value === this.OpojnaSredstva)?.value;
            },
            set(value) {
                this.OpojnaSredstva = value;
            },
        },

        selectedOpojnaSredstvaPocinioc: {
            get() {
                return this.daNeList.find(item => item.value === this.OpojnaSredstvaPocinioc)?.value;
            },
            set(value) {
                this.OpojnaSredstvaPocinioc = value;
            },
        },

        selectedZlostavljniKaoDijete: {
            get() {
                return this.daNeList.find(item => item.value === this.ZlostavljniKaoDijete)?.value;
            },
            set(value) {
                this.ZlostavljniKaoDijete = value;
            },
        },

        selectedZlostavljaniKaoDijetePocinioc: {
            get() {
                return this.daNeList.find(item => item.value === this.ZlostavljaniKaoDijetePocinioc)?.value;
            },
            set(value) {
                this.ZlostavljaniKaoDijetePocinioc = value;
            },
        },

        selectedVrstaMjere: {
            get() {
                return this.vrstaMjereList.find(item => item.value === this.VrstaMjere)?.value;
            },
            set(value) {
                this.VrstaMjere = value;
            },
        },
        selectedTip: {
            get() {
                return this.tipList.find(item => item.value === this.Tip)?.value;
            },
            set(value) {
                this.Tip = value;
            },
        },
        selectedMentalnoOboljenje: {
            get() {
                return this.MentalnoOboljenjepList.find(item => item.value === this.MentalnoOboljenje)?.value;
            },
            set(value) {
                this.MentalnoOboljenje = value;
            },
        },
        selectedPociniocMentalno: {
            get() {
                return this.MentalnoOboljenjepList.find(item => item.value === this.PociniocMentalno)?.value;
            },
            set(value) {
                this.PociniocMentalno = value;
            },
        },

    },
};

</script>

<style>
@media print {
    #menuBar {
        display: none;
    }

    #divider {
        display: none;
    }

    @page {
        margin-bottom: 2.3cm;
        /* adjust as needed */
    }

    #tablePrint {
        bottom: 10px;
    }

    #printButton {
        left: -9999px;
        width: 1px;
        height: 1px;
        visibility: hidden;
    }



    #filter {
        display: none;

    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    table {
        width: 100%;
        /* Adjust as needed */
    }

    th,
    td {
        overflow: hidden;
        padding-left: 10px;
    }
}

.custom-print-button {
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    /* Add other custom styles as needed */
}

body {
    margin: 0;
    padding: 0;
}
</style>
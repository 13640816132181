<!-- SlucajForm.vue -->
<template>
    <!-- <div class="position-absolute"> -->
    <div>

        <div class="hidden-on-screen">
            <div class="container-fluid">
                <div class="row justify-content-between">
                    <div class="col-lg-8">
                        <div class="form-group">
                            <div class="col-auto mx-auto">
                                <img src="./IspisLogo.png" alt="Image" style="max-width: 300px; margin-left: 500px;">
                            </div>
                            <div class="col-auto mx-auto">
                                <label for="name" class="form-label"
                                    style="font-weight: bold; margin-bottom: 30px; margin-left: 250px; text-align: center;">SKLONIŠTE
                                    ZA ŽENE I DJECU ŽRTVE
                                    NASILJA U PORODICI</label>
                            </div>
                            <br> <br> <br> <br>

                            <label for="title" class="form-label"
                                style="font-weight: bold; margin-bottom: 100px;  margin-left: 250px; text-align: center;">UPITNIK
                                ZA {{ slucaj.Tip === 'Djevojka' ? 'DJEVOJKE' : 'ŽENE' }}</label>
                            <br> <br>
                            <div class="row">
                                <div class="col-md-2" style="width: 160px; ">
                                    <label for="name" class="form-label"
                                        style="font-weight: bold; text-align: left; margin-bottom: 5px;">Ime i
                                        prezime:</label>
                                </div>
                                <div class="col-md-3" style=" text-align: left;">
                                    <label for="name" class="form-label"
                                        style="font-weight: text-align: left; margin-bottom: 5px;">{{ slucaj.Ime + " "
                                            + slucaj.Prezime }}</label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-3" style="width: 160px; ">

                                    <label for="dateAdmission" class="form-label"
                                        style="font-weight: bold; text-align: left; margin-bottom: 5px;">Datum
                                        prijema:</label>
                                </div>
                                <div class="col-md-3" style=" text-align: left;">
                                    <label for="dateAdmission" class="form-label"
                                        style="font-weight: text-align: left; margin-bottom: 5px;">{{ formatDate(slucaj.DatumUlaska)
                                        }}</label>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-2" style="width: 160px; ">

                                    <label for="dateDischarge" class="form-label"
                                        style="font-weight: bold; text-align: left; margin-bottom: 5px;">Datum
                                        otpusta:</label>
                                </div>
                                <div class="col-md-2" style=" text-align: left;">
                                    <label for="dateDischarge" class="form-label"
                                        style="font-weight: text-align: left; margin-bottom: 5px;">{{ formatDate(slucaj.DatumOdlaska)
                                        }}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <div class="container text-start">
            <h2 v-if="editMode">Podaci o korisnika</h2>
            <h2 class="bg-light p-2" v-else>Podaci o korisniku</h2>
            <div>

            </div>
            <div>
                <form>

                    <!-- Tab 1 form fields -->
                    <!-- Move your existing form fields for the first tab here -->
                    <br>
                    <div class="container custom-dashboard"
                        style="border:1px solid #b7b7b7; width: 100%; border-radius: 25px">
                        <b><br>
                            <p class="h3">OSNOVNI PODACI</p>
                        </b><br>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label for="tip" class="col-sm-2 col-form-label">Tip</label>
                                    <select class="form-control" id="tip" v-model="slucaj.Tip">
                                        <option disabled value="">Odaberite tip</option>
                                        <option value="Žena">Žena</option>
                                        <option value="Djevojka">Djevojka</option>
                                    </select>
                                </div>

                                <div class="mb-3">
                                    <label for="Ime" class="form-label">Ime</label>
                                    <input type="text" class="form-control" id="Ime" v-model="slucaj.Ime" required />
                                </div>


                                <div class="mb-3">
                                    <label for="DatumRodjenja" class="form-label">Datum rodenja</label>
                                    <input type="date" class="form-control" id="DatumRodjenja"
                                        v-model="slucaj.DatumRodjenja" />
                                </div>

                                <div class="mb-3">
                                    <label for="Drzavljanstvo" class="form-label">Drzavljanstvo</label>
                                    <input type="text" class="form-control" id="Drzavljanstvo"
                                        v-model="slucaj.Drzavljanstvo" />
                                </div>


                                <div class="mb-3">
                                    <label for="DobKorisnika" class="form-label">Dob korisnika</label>
                                    <select class="form-control" id="dobDropdown" v-model="slucaj.DobKorisnika">
                                        <option disabled value="">Odaberite Dob korisnika</option>
                                        <option v-for="dob in dobKorisnika" :key="dob.value" :value="dob.value">{{ dob.label
                                        }}</option>
                                    </select>
                                </div>


                            </div>
                            <!-- column 2 -->
                            <div class="col-md-4">
                                <br><br><br><br>
                                <div class="mb-3">
                                    <label for="Prezime" class="form-label">Prezime</label>
                                    <input type="text" class="form-control" id="Prezime" v-model="slucaj.Prezime" />
                                </div>
                                <div class="mb-3">
                                    <label for="MjestoRodjenja" class="form-label">Mjesto rođenja</label>
                                    <input type="text" class="form-control" id="MjestoRodjenja"
                                        v-model="slucaj.MjestoRodjenja" />
                                </div>

                                <div class="mb-3">
                                    <label for="BrojLK" class="form-label">Broj LK</label>
                                    <input type="text" class="form-control" id="BrojLK" v-model="slucaj.BrojLK" />
                                </div>
                                <div class="mb-3">
                                    <label for="DatumUlaska" class="form-label">Datum dolaska u sigurnu kucu</label>
                                    <input type="date" class="form-control" id="DatumUlaska" v-model="slucaj.DatumUlaska" />
                                </div>

                            </div>
                            <!-- column 3 -->
                            <div class="col-md-4">
                                <div class="mb-3" style="display: none;">
                                    <label for="Pol" class="form-label">Pol</label>
                                    <select class="form-control" id="Pol" v-model="selectedPol">
                                        <option disabled value="">Odaberite pol</option>
                                        <option v-for="pol in polovi" :key="pol.value" :value="pol.value">{{
                                            pol.label }}</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <br><br><br><br>
                                    <label for="ImeRoditelja" class="form-label">Ime roditelja</label>
                                    <input type="text" class="form-control" id="ImeRoditelja"
                                        v-model="slucaj.ImeRoditelja" />
                                </div>
                                <div class="mb-3">
                                    <label for="Adresa" class="form-label">Adresa</label>
                                    <input type="text" class="form-control" id="Adresa" v-model="slucaj.Adresa" />
                                </div>
                                <div class="mb-3">
                                    <label for="JMBG" class="form-label">JMBG</label>
                                    <input type="text" class="form-control" id="JMBG" v-model="slucaj.JMBG" />
                                </div>
                                <div class="mb-3">
                                    <label for="DatumOdlaska" class="form-label">Datum odlaska iz sigurne kuće</label>
                                    <input type="date" class="form-control" id="DatumOdlaska"
                                        v-model="slucaj.DatumOdlaska" />
                                </div>


                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="hidden-on-screen">
                        <br> <br> <br> <br>
                    </div>
                    <div class="container custom-dashboard"
                        style="border:1px solid #cecece; width: 100%; border-radius: 25px">
                        <b><br>
                            <p class="h3">Lični podaci</p>
                        </b><br>
                        <div class="row">


                            <div class="col-md-4">
                                <!-- need to amke it work, this is just GUI for now -->
                                <div class="mb-3">
                                    <label for="Radni_status" class="form-label">Radni status</label>
                                    <select class="form-control" id="Radni_status" v-model="slucaj.RadniStatus">
                                        <option disabled value="">Odaberite Radni status</option>
                                        <option v-for="status in radniStatusList" :key="status.value" :value="status.value">
                                            {{ status.label }}</option>
                                    </select>
                                </div>


                                <div class="mb-3">
                                    <label for="Invalid" class="form-label">Da li je korisnica osoba sa
                                        invaliditetom</label>
                                    <select class="form-control" id="Invalid" v-model="slucaj.Invalid">
                                        <option disabled value="">Odaberite da li je korisnica invalid</option>
                                        <option v-for="status in daNeList" :key="status.value" :value="status.value">{{
                                            status.label }}</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="djeca" class="form-label">Da li imate djecu</label>
                                    <select class="form-control" id="Invalid" v-model="slucaj.Djeca">
                                        <option disabled value="">Da li imate djecu</option>
                                        <option v-for="status in daNeList" :key="status.value" :value="status.value">{{
                                            status.label }}</option>
                                    </select>
                                </div>


                                <div class="mb-3">
                                    <label for="StatusKorisnikaPoIzlasku" class="form-label">Status korisnice po izlasku iz
                                        sigurne kuće</label>
                                    <select class="form-control" id="StatusKorisnikaPoIzlasku"
                                        v-model="slucaj.StatusKorisnikaPoIzlasku">
                                        <option disabled value="">Status korisnice po izlasku iz sigurne kuće</option>
                                        <option v-for="status in statusKorisnikaPoIzlaskuList" :key="status.value"
                                            :value="status.value">{{ status.label }}</option>
                                    </select>
                                </div>

                                <div class="mb-3">
                                    <label for="ZlostavljaniKaoDijete" class="form-label">Da li ste Vi kao dijete bili
                                        zlostavljani</label>
                                    <select class="form-control" id="ZlostavljaniKaoDijete"
                                        v-model="slucaj.ZlostavljniKaoDijete">
                                        <option disabled value="">Odaberi</option>
                                        <option v-for="status in daNeList" :key="status.value" :value="status.value">{{
                                            status.label }}</option>
                                    </select>
                                </div>
                            </div>
                            <!-- column 2 -->
                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label for="Relacija_sa_pociniocem" class="form-label">Relacija sa pociniocem</label>
                                    <select class="form-control" id="Relacija_sa_pociniocem"
                                        v-model="slucaj.RelacijaSaPociniocem">
                                        <option disabled value="">Odaberite Relacija sa pociniocem</option>
                                        <option v-for="status in pociniocNasiljaList" :key="status.value"
                                            :value="status.value">{{ status.label }}</option>
                                    </select>
                                </div>

                                <div class="mb-3">
                                    <label for="InvalidOpisno" class="form-label">Invalid opisno</label>
                                    <input type="text" class="form-control" id="InvalidOpisno"
                                        v-model="slucaj.InvalidOpisno" />
                                </div>
                                <div class="mb-3">
                                    <label for="bracni_status" class="form-label">Bracni status</label>
                                    <select class="form-control" id="bracni_status" v-model="slucaj.BracniStatus">
                                        <option disabled value="">Odaberite bracni status</option>
                                        <option v-for="status in bracniStatusList" :key="status.value"
                                            :value="status.value">{{ status.label }}</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="brojDjece" class="form-label">Koliko djece imate</label>
                                    <input type="number" class="form-control" id="brojDjece" v-model="slucaj.BrojDjece"
                                        min="0">
                                </div>

                                <div class="mb-3">
                                    <label for="NacinSmjestajaUSig" class="form-label">Način smještaja žrtve u sigurnu
                                        kuću</label>
                                    <select class="form-control" id="NacinSmjestajaUSig"
                                        v-model="slucaj.NacinSmjestajaUSig">
                                        <option disabled value="">Odaberite nacin smjestaja</option>
                                        <option v-for="status in nacinSmjestajaUSigList" :key="status.value"
                                            :value="status.value">{{ status.label }}</option>
                                    </select>
                                </div>


                            </div>
                            <!-- column 3 -->
                            <div class="col-md-4">



                                <div class="mb-3">
                                    <label for="djecaUSigurnojKuci" class="form-label">Da li su djeca smještena u sigurnu
                                        kuću</label>
                                    <select class="form-control" id="djetePoslijedice" v-model="slucaj.DjecaUSigurnoj">
                                        <option disabled value="">Djeca u sigurnoj kuci</option>
                                        <option v-for="status in daNeList" :key="status.value" :value="status.value">{{
                                            status.label }}</option>
                                    </select>
                                </div>

                                <div class="mb-3">
                                    <!-- <label for="OdlukaONapustanju" class="form-label">Odluku o napuštanju sigurne kuće donijeli</label> -->
                                    <label for="OdlukaONapustanju" class="form-label">Odluku o napuštanju doinijeli</label>
                                    <select class="form-control" id="OdlukaONapustanju" v-model="slucaj.OdlukaONapustanju">
                                        <option disabled value="">Odluku o napuštanju sigurne kuće donijel</option>
                                        <option v-for="status in odlukaONapustanjuList" :key="status.value"
                                            :value="status.value">{{ status.label }}</option>
                                    </select>
                                </div>

                                <div class="mb-3">
                                    <label for="OpojnaSredstva" class="form-label">Da li ste koristili opojna
                                        sredstva?</label>
                                    <select class="form-control" id="OpojnaSredstva" v-model="slucaj.OpojnaSredstva">
                                        <option disabled value="">Odaberi</option>
                                        <option v-for="status in daNeList" :key="status.value" :value="status.value">{{
                                            status.label }}</option>
                                    </select>
                                </div>

                                <div class="mb-3">
                                    <label for="brojDjeceUsigurnojKuci" class="form-label">Broj djece smještenih u sigurnoj
                                        kući</label>
                                    <input type="number" class="form-control" id="brojDjeceUsigurnojKuci"
                                        v-model="slucaj.BrojDjeceUsigurnojKuci" min="0">
                                </div>

                            </div>
                        </div>
                    </div>

                    <br>
                    <div class="hidden-on-screen">
                        <br> <br>
                    </div>
                    <div class="container custom-dashboard"
                        style="border:1px solid #b7b7b7; width: 100%; border-radius: 25px">
                        <b><br>
                            <p class="h3">SOCIODEMOGRAFSKI PODACI</p>
                        </b><br>
                        <div class="row">

                            <div class="col-3">
                                <br>
                                <!-- <label for="OdlukaONapustanju" class="form-label">Odluku o napuštanju sigurne kuće donijeli</label> -->
                                <label for="NivoObrazovanja" class="form-label">Nivo obrazovanja</label>
                                <select class="form-control" id="NivoObrazovanja" v-model="slucaj.NivoObrazovanja">
                                    <option disabled value="">Odaberite obrazovanje</option>
                                    <option v-for="status in nivoObrazovanjaList" :key="status.value" :value="status.value">
                                        {{ status.label }}</option>
                                </select>
                            </div>
                            <div class="col-3">
                                <br>
                                <label for="LicniPrihodi" class="form-label">Lični mjesečni prihodi</label>
                                <div class="input-group">
                                    <span class="input-group-text">BAM</span>
                                    <input type="number" class="form-control" id="LicniPrihodi"
                                        v-model="slucaj.LicniPrihodi" step="1">
                                </div>
                            </div>

                            <div class="col-3">
                                <label for="PripadnikDrustveneSkupine" class="form-label">Pripadnik/ca druge društvene
                                    skupine</label>
                                <select class="form-control" id="PripadnikDrustveneSkupine"
                                    v-model="slucaj.PripadnikDrustveneSkupine">
                                    <option disabled value="">Odaberite da li je pripadnik drustvene skupine</option>
                                    <option v-for="status in daNeList" :key="status.value" :value="status.value">{{
                                        status.label }}</option>
                                </select>
                            </div>

                            <div class="col-3">
                                <br>
                                <label for="StambeniStatus" class="form-label">Stambeni status</label>
                                <select class="form-control" id="StambeniStatus" v-model="slucaj.StambeniStatus">
                                    <option disabled value="">Odaberite stambeni status</option>
                                    <option v-for="status in StambeniStatusList" :key="status.value" :value="status.value">
                                        {{
                                            status.label }}</option>
                                </select>
                            </div>

                            <div class="row">
                                <div class="col-5">
                                    <label for="LicnaImovina" class="form-label">Licna imovina</label>
                                    <textarea v-model=slucaj.LicnaImovina placeholder="Stan, kuca, auto itd."
                                        class="form-control" rows="4" style="width: 100%;"></textarea>
                                </div>

                                <div class="col-7">
                                    <label for="Komentar" class="form-label">Komentar</label>
                                    <textarea v-model=slucaj.Komentar placeholder="Opci komentar" class="form-control"
                                        rows="4" style="width: 100%;"></textarea>
                                </div>
                                <div>
                                    <br>
                                    <div class="col-7 d-flex align-items-center">
                                        <!-- Add child -->
                                        <Multiselect v-model="selectedChild" mode="single" :searchable="true"
                                            :close-on-select="true" :options="childrenList"
                                            placeholder="Pretražite po imenu, prezimenu ili matičnom broju..."
                                            style="margin-right: 20px;" />
                                        <button type="button" @click="addChild" class="btn btn-success" style="width: 27%;">
                                            <span> </span><i class="fa-solid fa-user-plus"> </i> Dodaj dijete</button>
                                    </div>

                                    <div class="col-6">
                                        <ul class="list-group mt-3">
                                            <li v-for="id in slucaj.children" :key="id"
                                                class="list-group-item d-flex justify-content-between align-items-center">
                                                {{ getChildName(id) }}
                                                <button @click="removeChild(id)" class="btn btn-danger btn-sm"><i
                                                        class="fa-solid fa-user-minus"></i></button>
                                            </li>
                                        </ul>
                                        <p v-if="duplicateChildWarning" class="text-danger mt-2">
                                            Dijete je već dodano
                                            <button @click="closeWarning" class="btn-close" aria-label="Close"></button>
                                        </p>

                                        <br>
                                    </div>
                                </div>
                            </div>









                        </div>
                    </div>
                    <br>
                    <div class="hidden-on-screen">
                        <br> <br> <br> <br> <br><br><br><br>
                    </div>
                    <div class="container custom-dashboard"
                        style="border:1px solid #b7b7b7; width: 100%; border-radius: 25px">
                        <br>
                        <p class="h3">Zaštitne mjere</p><br>
                        <div class="row">

                            <div class="col-md-4">

                                <div class="mb-3">
                                    <label for="VrstaMjere" class="form-label">Vrsta mjere</label>
                                    <Multiselect v-model="slucaj.VrstaMjereArray" mode="tags" :close-on-select="false"
                                        :searchable="true" :create-option="false" :options=vrstaMjereList />
                                </div>

                                <div class="mb-3">
                                    <label for="TrajanjeMjere" class="form-label">Trajanje Mjere</label>
                                    <input type="text" class="form-control" id="TrajanjeMjere"
                                        v-model="slucaj.TrajanjeMjere" />
                                </div>
                            </div>

                            <div class="col-md-8">
                                <div class="col-12">
                                    <label for="KomentarMjere" class="form-label">Komentar </label>
                                    <textarea v-model=slucaj.KomentarMjere placeholder="Komentar mjere" class="form-control"
                                        rows="5" style="width: 100%;"></textarea>
                                </div>
                            </div>
                        </div>
                        <br>
                    </div>
                    <br>

                    <div class="container custom-dashboard rounded-3">
                        <div class="row">
                            <div class="container custom-dashboard"
                                style="border:1px solid #b7b7b7; width: 100%; border-radius: 25px">

                                <b><br>
                                    <p class="h3">PODACI O PREŽIVLJENOM NASILJU</p>
                                </b><br>
                                <div class="row">

                                    <div class="col-md-4">
                                        <div class="mb-3">
                                            <label for="DatumZadnjegNasilja" class="form-label">Datum zadnjeg
                                                nasilja</label>
                                            <input type="date" class="form-control" id="DatumZadnjegNasilja"
                                                v-model="slucaj.DatumZadnjegNasilja" />
                                        </div>
                                        <div class="mb-3">
                                            <label for="VrstaNasilja" class="form-label">Vrsta nasilja</label>
                                            <Multiselect v-model="slucaj.VrstaNasiljaArray" mode="tags"
                                                :close-on-select="false" :searchable="true" :create-option="false"
                                                :options=vrstaNasiljaList />
                                        </div>

                                        <div class="mb-3">
                                            <label for="MentalnoOboljenje" class="form-label">Mentalno oboljenje</label>
                                            <select class="form-control" id="MentalnoOboljenje"
                                                v-model="slucaj.MentalnoOboljenje">
                                                <option disabled value="">Da li je mentalno oboljeo</option>
                                                <option v-for="status in mentalnoOboljenjeList" :key="status.value"
                                                    :value="status.value">{{ status.label }}</option>
                                            </select>
                                        </div>

                                        <div class="mb-3">
                                            <label for="PomocOdNavedenihInstitucija" class="form-label">Pomoć koju je
                                                dobila
                                                od
                                                navedenih institucija?</label>
                                            <input type="text" class="form-control" id="PomocOdNavedenihInstitucija"
                                                v-model="slucaj.PomocOdNavedenihInstitucija" />
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <!-- <div class="input-group mb-3">  da bude u liniji ovaj fazon  -->
                                        <div class="mb-3">
                                            <!-- <span class="input-group-text">Vrijeme nasilja</span> -->
                                            <label for="Vrsta_mjere" class="form-label">Vrijeme nasilja</label>

                                            <input type="time" class="form-control" v-model="slucaj.VrijemeNasilja">
                                        </div>
                                        <div class="mb-3">
                                            <label for="TrajanjeNasilja" class="form-label">Trajanje nasilja</label>
                                            <select class="form-control" id="TrajanjeNasilja"
                                                v-model="slucaj.TrajanjeNasilja">
                                                <option disabled value="">Odaberite trajanje nasilja</option>
                                                <option v-for="status in trajanjeNasiljaList" :key="status.value"
                                                    :value="status.value"> {{ status.label }}</option>
                                            </select>
                                        </div>

                                        <div class="mb-3">
                                            <label for="Lijecenje" class="form-label">Da li je bila liječena</label>
                                            <select class="form-control" id="Lijecenje" v-model="slucaj.Lijecenje">
                                                <option disabled value="">Odaberite liječenje</option>
                                                <option v-for="status in lijecenaList" :key="status.value"
                                                    :value="status.value"> {{
                                                        status.label }}</option>
                                            </select>
                                        </div>

                                        <div class="mb-3">
                                            <label for="OpisSituacijeNasilja" class="form-label">Opis situacije
                                                nasilja</label>
                                            <input type="text" class="form-control" id="OpisSituacijeNasilja"
                                                v-model="slucaj.OpisSituacijeNasilja" />
                                        </div>

                                    </div>
                                    <div class="col-md-5">
                                        <div class="mb-3">
                                            <label for="MjestoNasilja" class="form-label">Mjesto nasilja</label>
                                            <input type="text" class="form-control" id="MjestoNasilja"
                                                v-model="slucaj.MjestoNasilja" />
                                        </div>

                                        <div class="mb-3">

                                            <label for="PosljediceNasilja" class="form-label">Poslijedice
                                                nasilja</label>
                                            <Multiselect v-model="slucaj.PosljediceNasiljaArray" mode="tags"
                                                :close-on-select="false" :searchable="true" :create-option="false"
                                                :options=posljediceNasiljaList />
                                        </div>

                                        <div class="mb-4">
                                            <label for="Institucije" class="form-label">Kojim institucijama se
                                                obraćala</label>
                                            <Multiselect v-model="slucaj.InstitucijeArray" mode="tags"
                                                :close-on-select="false" :searchable="true" :create-option="false"
                                                :options=institucijeList />
                                        </div>

                                        <div class="mb-3">
                                            <label for="UzrokNasilja" class="form-label">Mogući uzroci nasilja</label>
                                            <Multiselect v-model="slucaj.UzrokNasiljaArray" mode="tags"
                                                :close-on-select="false" :searchable="true" :create-option="false"
                                                :options=UzrokNasiljaList />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <br>
                    <div class="hidden-on-screen">
                        <br> <br> <br> <br> <br><br>
                    </div>
                    <div class="col-md-12">
                        <div class="container custom-dashboard"
                            style="border:1px solid #b7b7b7; width: 100%; border-radius: 25px">
                            <br><b>
                                <p class="h3">SIGURNOSNI RIZICI</p>
                            </b><br>
                            <div class="row">
                                <div class="col-md-3">
                                    <label for="PosjedujeOruzije" class="form-label">
                                        Da li počinilac nasilja <br>
                                        posjeduje oružje</label>
                                    <select class="form-control" id="PosjedujeOruzije" v-model="slucaj.PosjedujeOruzije">
                                        <option disabled value="">Da li počinilac nasilja
                                            posjeduje oružje
                                        </option>
                                        <option v-for="status in PosjedujeOruzijeList" :key="status.value"
                                            :value="status.value"> {{ status.label }}</option>
                                    </select>
                                </div>

                                <div class="col-md-3">
                                    <br>
                                    <label for="PrijetiUbistvom" class="form-label">Da li
                                        počinilac nasilja prijeti ubistvom</label>
                                    <select class="form-control" id="PrijetiUbistvom" v-model="slucaj.PrijetiUbistvom">
                                        <option disabled value="">Da li počinilac nasilja prijeti ubistvom?</option>
                                        <option v-for="status in daNeList" :key="status.value" :value="status.value"> {{
                                            status.label }}</option>
                                    </select>
                                </div>

                                <div class="col-md-3">
                                    <br>

                                    <label for="PrijetiSamoubistvom" class="form-label">Da li
                                        počinilac nasilja prijeti samoubistvom</label>
                                    <select class="form-control" id="PrijetiSamoubistvom"
                                        v-model="slucaj.PrijetiSamoubistvom">
                                        <option disabled value="">Da li počinilac nasilja prijeti samoubistvom?</option>
                                        <option v-for="status in daNeList" :key="status.value" :value="status.value"> {{
                                            status.label }}</option>
                                    </select>
                                </div>

                                <div class="col-md-3">
                                    <label for="StahNasilja" class="form-label">Da li žrtva nasilja doživljava
                                        strah od učinioca nasilja</label>
                                    <select class="form-control" id="StahNasilja" v-model="slucaj.StahNasilja">
                                        <option disabled value="">Da li žrtva nasilja doživljava strah od
                                            učinioca nasilja?</option>
                                        <option v-for="status in daNeList" :key="status.value" :value="status.value"> {{
                                            status.label }}</option>
                                    </select>
                                </div>

                                <div class="col-md-3">
                                    <label for="Rizik" class="form-label">Procjena rizika od <br> strane stručnog
                                        tima</label>
                                    <select class="form-control" id="Rizik" v-model="slucaj.Rizik">
                                        <option disabled value="">Procjena rizika od strane stručnog tima
                                        </option>
                                        <option v-for="status in RizikList" :key="status.value" :value="status.value"> {{
                                            status.label }}</option>
                                    </select>
                                </div>
                            </div>
                            <br>



                        </div>
                    </div>
                    <br>
                    <div class="container ">
                        <br>
                        <b>
                            <p class="h2">KARTON POČINIOCA</p>
                        </b>
                        <br>

                        <div class="container custom-dashboard"
                            style="border:1px solid #b7b7b7; width: 100%; border-radius: 25px">
                            <br>
                            <b>
                                <p class="h3">OSNOVNI PODACI</p>
                            </b>
                            <br>

                            <div class="row">
                                <div class="col-md-3">


                                    <div class="mb-3">
                                        <label for="PociniocIme" class="form-label">Ime</label>
                                        <input type="text" class="form-control" id="PociniocIme"
                                            v-model="slucaj.PociniocIme" />
                                    </div>


                                    <div class="mb-3">
                                        <label for="PociniocDatumRodjenja" class="form-label">Datum rodjenja</label>
                                        <input type="date" class="form-control" id="PociniocDatumRodjenja"
                                            v-model="slucaj.PociniocDatumRodjenja" />
                                    </div>
                                    <div class="mb-3">
                                        <label for="PociniocDobKorisnika" class="form-label">Dob korisnika</label>
                                        <select class="form-control" id="PociniocDobKorisnika"
                                            v-model="slucaj.PociniocDobKorisnika">
                                            <option disabled value="">Odaberite Dob korisnika</option>
                                            <option v-for="dropdown in dobKorisnika" :key="dropdown.value"
                                                :value="dropdown.value">{{ dropdown.label }}</option>
                                        </select>
                                    </div>

                                </div>
                                <!-- column 2 -->
                                <div class="col-md-3">

                                    <div class="mb-3">
                                        <label for="PociniocPrezime" class="form-label">Prezime</label>
                                        <input type="text" class="form-control" id="PociniocPrezime"
                                            v-model="slucaj.PociniocPrezime" />
                                    </div>
                                    <div class="mb-3">
                                        <label for="PociniocMjesto" class="form-label">Mjesto rođenja</label>
                                        <input type="text" class="form-control" id="PociniocMjesto"
                                            v-model="slucaj.PociniocMjesto" />
                                    </div>

                                </div>
                                <!-- column 3 -->
                                <div class="col-md-3">
                                    <div class="mb-3">
                                        <label for="PociniocImeRoditelja" class="form-label">Ime roditelja</label>
                                        <input type="text" class="form-control" id="PociniocImeRoditelja"
                                            v-model="slucaj.PociniocImeRoditelja" />
                                    </div>

                                    <div class="mb-3">
                                        <label for="PociniocAdresa" class="form-label">Adresa</label>
                                        <input type="text" class="form-control" id="PociniocAdresa"
                                            v-model="slucaj.PociniocAdresa" />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="mb-3">
                                        <label for="PociniocPol" class="form-label">Pol</label>
                                        <select class="form-control" id="PociniocPol" v-model="slucaj.PociniocPol">
                                            <option disabled value="">Odaberite pol</option>
                                            <option v-for="pol in polovi" :key="pol.value" :value="pol.value">{{
                                                pol.label }}</option>
                                        </select>
                                    </div>

                                    <div class="mb-3">
                                        <label for="PociniocDrzavljanstvo" class="form-label">Drzavljanstvo</label>
                                        <input type="text" class="form-control" id="PociniocDrzavljanstvo"
                                            v-model="slucaj.PociniocDrzavljanstvo" />
                                    </div>
                                </div>
                            </div>


                        </div>
                        <br>
                        <div class="container custom-dashboard"
                            style="border:1px solid #b7b7b7; width: 100%; border-radius: 25px">
                            <br>
                            <b>
                                <p class="h3">LIČNI I SOCIODEMOGRAFSKI PODACI</p>
                            </b>
                            <br>
                            <div class="row">

                                <div class="col-md-3">
                                    <div class="mb-3">
                                        <label for="PociniocRadniStatus" class="form-label">Radni status</label>
                                        <select class="form-control" id="PociniocRadniStatus"
                                            v-model="slucaj.PociniocRadniStatus">
                                            <option disabled value="">Odaberite radni status</option>
                                            <option v-for="status in radniStatusList" :key="status.value"
                                                :value="status.value">{{ status.label }}</option>
                                        </select>
                                    </div>

                                    <div class="mb-3">
                                        <label for="PociniocImovina" class="form-label">Licna imovina</label>
                                        <textarea v-model=slucaj.PociniocImovina placeholder="Stan, kuca, auto itd."
                                            class="form-control" rows="4" style="width: 100%;"></textarea>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="mb-3">
                                        <label for="PociniocBracniStatus" class="form-label">Bracni status</label>
                                        <select class="form-control" id="PociniocBracniStatus"
                                            v-model="slucaj.PociniocBracniStatus">
                                            <option disabled value="">Odaberite bracni status</option>
                                            <option v-for="status in bracniStatusList" :key="status.value"
                                                :value="status.value">{{ status.label }}</option>
                                        </select>
                                    </div>

                                    <div class="mb-3">
                                        <label for="PociniocStamebiStatus" class="form-label">Stambeni status</label>
                                        <select class="form-control" id="PociniocStamebiStatus"
                                            v-model="slucaj.PociniocStamebiStatus">
                                            <option disabled value="">Odaberite stambeni status</option>
                                            <option v-for="status in StambeniStatusList" :key="status.value"
                                                :value="status.value"> {{ status.label }}</option>
                                        </select>
                                    </div>

                                    <div class="mb-3">
                                        <label for="PociniocZavisnost" class="form-label">Zavisnost</label>
                                        <Multiselect v-model="slucaj.PociniocZavisnostArray" mode="tags"
                                            :close-on-select="false" :searchable="true" :create-option="false"
                                            :options=PociniocZavisnostList />
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="mb-3">
                                        <label for="PociniocNivoObrazovanja" class="form-label">Nivo obrazovanja</label>
                                        <select class="form-control" id="PociniocNivoObrazovanja"
                                            v-model="slucaj.PociniocNivoObrazovanja">
                                            <option disabled value="">Odaberite obrazovanje</option>
                                            <option v-for="status in nivoObrazovanjaList" :key="status.value"
                                                :value="status.value">{{ status.label }}</option>
                                        </select>
                                    </div>

                                    <div class="mb-3">
                                        <label for="PociniocMentalno" class="form-label">Mentalno oboljenje</label>
                                        <select class="form-control" id="PociniocMentalno"
                                            v-model="slucaj.PociniocMentalno">
                                            <option disabled value="">Da li je mentalno oboljeo</option>
                                            <option v-for="status in mentalnoOboljenjeList" :key="status.value"
                                                :value="status.value">{{ status.label }}</option>
                                        </select>
                                    </div>

                                    <div class="mb-3">
                                        <label for="ZlostavljaniKaoDijetePocinioc" class="form-label">Da li ste Vi kao
                                            dijete bili zlostavljani</label>
                                        <select class="form-control" id="ZlostavljaniKaoDijetePocinioc"
                                            v-model="slucaj.ZlostavljaniKaoDijetePocinioc">
                                            <option disabled value="">Odaberi</option>
                                            <option v-for="status in daNeList" :key="status.value" :value="status.value">{{
                                                status.label }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="mb-3">
                                        <label for="PociniocPrihodi" class="form-label">Lični mjesečni prihodi</label>
                                        <div class="input-group">
                                            <span class="input-group-text">BAM</span>
                                            <input type="number" class="form-control" id="PociniocPrihodi"
                                                v-model="slucaj.PociniocPrihodi" step="1">
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <label for="PociniocLijecen" class="form-label">Da li je liječen/a?</label>
                                        <select class="form-control" id="PociniocLijecen" v-model="slucaj.PociniocLijecen">
                                            <option disabled value="">Odaberite liječenje</option>
                                            <option v-for="status in lijecenaList" :key="status.value"
                                                :value="status.value"> {{ status.label }}</option>
                                        </select>
                                    </div>

                                    <div class="mb-3">
                                        <label for="OpojnaSredstvaPocinioc" class="form-label">Da li je koristio opojna
                                            sredstva?</label>
                                        <select class="form-control" id="OpojnaSredstvaPocinioc"
                                            v-model="slucaj.OpojnaSredstvaPocinioc">
                                            <option disabled value="">Odaberi</option>
                                            <option v-for="status in daNeList" :key="status.value" :value="status.value">{{
                                                status.label }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br>
                        <div class="container custom-dashboard"
                            style="border:1px solid #b7b7b7; width: 100%; border-radius: 25px;">
                            <br>
                            <p class="h3"> PODACI O ISTORIJI NASILJA I PREDUZETIM MJERAMA</p>
                            <br>
                            <div class="row">

                                <div class="col-4">
                                    <br>
                                    <label for="PociniocKaznjavanRanije" class="form-label">Da li je ranije
                                        kažnjavan/a
                                        zbog nasilničkog ponašanja</label>
                                    <select class="form-control" id="PociniocKaznjavanRanije"
                                        v-model="slucaj.PociniocKaznjavanRanije">
                                        <option disabled value="">Odaberite da li je kažnjavan/a ranije</option>
                                        <option v-for="status in daNeList" :key="status.value" :value="status.value"> {{
                                            status.label }}</option>
                                    </select>
                                    <label for="PociniocMjerePrijeSmjestaja" class="form-label">Mjere prije
                                        smještaja
                                        žrtve u sigurnu kuću</label>
                                    <input type="text" class="form-control" id="PociniocMjerePrijeSmjestaja"
                                        v-model="slucaj.PociniocMjerePrijeSmjestaja" />
                                </div>

                                <div class="col-4">



                                    <label for="PociniocNasilanPremaDrugima" class="form-label">Da li je počinitelj
                                        nasilan i prema drugim osobama u porodici i/ili izvan porodične
                                        zajednice</label>
                                    <select class="form-control" id="PociniocNasilanPremaDrugima"
                                        v-model="slucaj.PociniocNasilanPremaDrugima">
                                        <option disabled value="">Odaberite</option>
                                        <option v-for="status in daNeList" :key="status.value" :value="status.value"> {{
                                            status.label }}</option>
                                    </select>
                                    <label for="PociniocMjereTokomBoravka" class="form-label">Mjere tokom boravka
                                        žrtve
                                        u sigurnoj kući</label>
                                    <input type="text" class="form-control" id="PociniocMjereTokomBoravka"
                                        v-model="slucaj.PociniocMjereTokomBoravka" />
                                </div>


                                <div class="col-4">
                                    <label for="PociniocKomentar" class="form-label">Komentar</label>
                                    <textarea v-model=slucaj.PociniocKomentar placeholder="" class="form-control" rows="5"
                                        style="width: 100%;"></textarea>
                                </div>
                            </div>
                            <br>
                            <br>
                        </div>
                        <br>
                    </div>
                    <br>

                </form>
                <div class="row">
                    <div class="row align-items-end">

                        <div class="col-md-1">
                            <br>
                            <button type="button" @click="submitForm" :disabled="isCreatingCase"
                                class="btn btn-success mt-1" style="float: left;">
                                {{ editMode ? "Spasi" : "Spasi" }}
                            </button>

                        </div>


                        <div class="col-md-1" v-if="editMode">
                            <br>
                            <DeleteButton @delete="deleteService"></DeleteButton>
                        </div>

                        <div class="col-md-1" v-if="editMode">
                            <br>

                            <button @click="printPage" class="btn btn-secondary">Print</button>
                        </div>

                        <div class="col-md-3">
                            <div v-if="errorMessage" class="alert alert-danger alert-dismissible mt-3"
                                style="float: right;">
                                {{ errorMessage }}
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>

                            <div v-if="successMessage" class="alert alert-success alert-dismissible mt-3"
                                style="max-width: 100%; float: right;">
                                {{ successMessage }}
                                <button type="button" class="btn-close" data-bs-dismiss="alert" @click="closeNotification"
                                    aria-label="Close"></button>
                            </div>
                        </div>
                    </div>


                </div>
                <br>

            </div>

        </div>
    </div>
</template>

<script>
import config from '../config'
import axios from 'axios';
import moment from 'moment';
import Multiselect from '@vueform/multiselect'
import DeleteButton from '@/components/DeleteButton.vue';

const apiUrl = `${config.backendUrl}/slucaj`;
const apiUrlLookupList = `${config.backendUrl}/lookup/list`;
const apiUrlChild = `${config.backendUrl}/child`;

export default {
    created() {
    },

    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
        initialSlucaj: {
            type: Object,
            default: () => ({
                ID: null,
                Tip: "",
                Ime: "",
                Prezime: "",
                ImeRoditelja: "",
                DatumRodjenja: "",
                DatumUlaska: "",
                DatumOdlaska: "",

                DobKorisnika: null,
                PociniocDobKorisnika: null,
                MjestoRodjenja: "",
                Pol: 24,
                JMBG: "",
                BrojLK: "",
                Drzavljanstvo: "",
                Adresa: "",
                RadniStatus: null,
                Invalid: null,
                Djeca: null,
                DjeteSaPosljedicamaNasilja: null,
                BracniStatus: null,
                RelacijaSaPociniocem: null,
                DjecaUSigurnoj: null,
                NacinSmjestajaUSig: null,
                Komentar: "",
                VrstaMjere: null,
                VrstaMjereArray: [],
                TrajanjeMjere: "",
                KomentarMjere: "",
                InvalidOpisno: "",
                BrojDjeceUsigurnojKuci: null,
                PrijetiUbistvom: null,
                PrijetiSamoubistvom: null,

                DatumZadnjegNasilja: "",
                VrijemeNasilja: "",
                MjestoNasilja: "",
                VrstaNasilja: null,
                VrstaNasiljaArray: [],
                TrajanjeNasilja: null,
                PosljediceNasilja: null,
                PosljediceNasiljaArray: null,

                MentalnoOboljenje: null,
                Lijecenje: null,
                Institucije: null,
                InstitucijeArray: [],

                PomocOdNavedenihInstitucija: "",
                OpisSituacijeNasilja: "",
                UzrokNasilja: null,
                UzrokNasiljaArray: [],

                PosjedujeOruzije: null,
                PocioniocPrijetiUbistvomSamoubustvom: null,
                StahNasilja: null,
                Rizik: null,

                // pocinioc
                PociniocIme: '',
                PociniocPrezime: '',
                PociniocImeRoditelja: '',
                PociniocMjesto: '',
                PociniocAdresa: '',
                PociniocDatumRodjenja: null,
                PociniocDrzavljanstvo: '',
                PociniocPol: null,
                // licni podaci pocinioca
                PociniocRadniStatus: null,
                PociniocBracniStatus: null,
                PociniocNivoObrazovanja: null,
                PociniocPrihodi: 0,
                PociniocImovina: '',
                PociniocStamebiStatus: null,
                PociniocMentalno: null,
                PociniocLijecen: null,
                PociniocZavisnost: null,
                PociniocZavisnostArray: [],
                // istorija o nasilju
                PociniocKaznjavanRanije: null,
                PociniocNasilanPremaDrugima: null,
                PociniocMjerePrijeSmjestaja: '',
                PociniocMjereTokomBoravka: '',
                PociniocKomentar: '',

                ZlostavljaniKaoDijetePocinioc: null,
                OpojnaSredstva: null,
                OpojnaSredstvaPocinioc: null,
                ZlostavljniKaoDijete: null
            }),
        },
        formMode: {
            type: String,
            default: ''
        },
    },
    emits: ['submitSuccess'],
    data() {
        return {
            slucaj: this.initialSlucaj || {
                ID: null,
                Tip: "",
                Ime: "",
                Prezime: "",
                ImeRoditelja: "",
                DatumRodjenja: "",
                DatumUlaska: "",
                DatumOdlaska: "",
                InvalidOpisno: "",
                BrojDjeceUsigurnojKuci: null,
                PrijetiUbistvom: null,
                PrijetiSamoubistvom: null,

                DobKorisnika: null,
                PociniocDobKorisnika: null,
                MjestoRodjenja: "",
                Pol: 24,
                JMBG: "",
                BrojLK: "",
                Adresa: "",
                Drzavljanstvo: "",
                Invalid: -1,
                Djeca: -1,
                DjeteSaPosljedicamaNasilja: -1,
                RelacijaSaPociniocem: 0,
                BracniStatus: 0,
                BrojDjece: 0,
                RadnoIskustvo: "",
                DjecaUSigurnoj: -1,
                NacinSmjestajaUSig: 0,
                OdlukaONapustanju: 0,
                StatusKorisnikaPoIzlasku: 0,
                NivoObrazovanja: 0,
                LicniPrihodi: '',
                LicnaImovina: '',
                PripadnikDrustveneSkupine: 0,
                StambeniStatus: null,
                Komentar: "",
                VrstaMjere: null,
                VrstaMjereArray: [],
                TrajanjeMjere: "",
                KomentarMjere: "",

                DatumZadnjegNasilja: "",
                VrijemeNasilja: "",
                MjestoNasilja: "",
                VrstaNasilja: "",
                VrstaNasiljaArray: [],
                TrajanjeNasilja: "",
                PosljediceNasilja: "",
                PosljediceNasiljaArray: [],

                MentalnoOboljenje: "",
                Lijecenje: "",
                Institucije: "",
                InstitucijeArray: [],
                PomocOdNavedenihInstitucija: "",
                OpisSituacijeNasilja: "",
                UzrokNasilja: null,
                UzrokNasiljaArray: [],

                PosjedujeOruzije: null,
                PocioniocPrijetiUbistvomSamoubustvom: null,
                StahNasilja: null,
                Rizik: null,

                PociniocIme: '',
                PociniocPrezime: '',
                PociniocImeRoditelja: '',
                PociniocMjesto: '',
                PociniocAdresa: '',
                PociniocDatumRodjenja: null,
                PociniocDrzavljanstvo: '',
                PociniocPol: null,
                // licni podaci pocinioca
                PociniocRadniStatus: null,
                PociniocBracniStatus: null,
                PociniocNivoObrazovanja: null,
                PociniocPrihodi: 0,
                PociniocImovina: '',
                PociniocStamebiStatus: null,
                PociniocMentalno: null,
                PociniocLijecen: null,
                PociniocZavisnost: null,
                PociniocZavisnostArray: [],
                //istorija o nasilju
                PociniocKaznjavanRanije: null,
                PociniocNasilanPremaDrugima: null,
                PociniocMjerePrijeSmjestaja: '',
                PociniocMjereTokomBoravka: '',
                PociniocKomentar: '',

                children: [],

                ZlostavljaniKaoDijetePocinioc: null,
                OpojnaSredstva: null,
                OpojnaSredstvaPocinioc: null,
                ZlostavljniKaoDijete: null,

            },
            radniStatusList: [],
            tipZrtve: [],
            polovi: [],
            dobKorisnika: [],
            nacinSmjestajaUSigList: [],
            pociniocNasiljaList: [],
            bracniStatusList: [],
            odlukaONapustanjuList: [],
            statusKorisnikaPoIzlaskuList: [],
            nivoObrazovanjaList: [],
            StambeniStatusList: [],
            vrstaNasiljaList: [],
            vrstaMjereList: [],
            trajanjeNasiljaList: [],
            posljediceNasiljaList: [],
            mentalnoOboljenjeList: [],
            lijecenaList: [],
            institucijeList: [],
            UzrokNasiljaList: [],
            PosjedujeOruzijeList: [],
            RizikList: [],
            PociniocZavisnostList: [],

            childList: [],
            selectedChild: '',
            duplicateChildWarning: false,
            currentTab: 1,
            errorMessage: '',
            successMessage: '',
            feedback: null,
            daNeList: [{ value: 0, label: 'Ne' }, { value: 1, label: 'Da' }],
            childrenList: [],
            isCreatingCase: false,
        };
    },
    methods: {
        formatDate(date) {
            if (date) {
                const parts = date.split('-');
                return `${parts[2]}-${parts[1]}-${parts[0]}`;
            }
            else return date;
        },
        printPage() {
            window.print();
        },
        deleteService() {
            const token = localStorage.getItem('jwt');
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${apiUrl}?ID=` + this.slucaj.ID, { headers })
                    .then(response => {
                        // Handle successful creation
                        console.log('Delted id ' + this.id + response.data);
                        this.$router.push({ name: 'SlucajList' });
                    })
                    .catch(error => {
                        // Handle error during creation
                        this.errorMessage = "Greska prilikom brisanja"

                        console.error('Error creating Person Trust:', error);
                        reject(error);
                    });
            });
        },

        closeWarning() {
            this.duplicateChildWarning = false;
        },
        formatSlucaj() {

            if (this.slucaj.DatumRodjenja != null) {
                this.slucaj.DatumRodjenja = formatDateFromUTC(this.slucaj.DatumRodjenja);
            }

            if (this.slucaj.DatumUlaska != null) {
                this.slucaj.DatumUlaska = formatDateFromUTC(this.slucaj.DatumUlaska);
            }
            if (this.slucaj.DatumOdlaska != null) {
                this.slucaj.DatumOdlaska = formatDateFromUTC(this.slucaj.DatumOdlaska);
            }

            if (this.slucaj.PociniocDatumRodjenja != null) {
                this.slucaj.PociniocDatumRodjenja = formatDateFromUTC(this.slucaj.PociniocDatumRodjenja);
            }
            if (this.slucaj.DatumZadnjegNasilja != null) {
                this.slucaj.DatumZadnjegNasilja = formatDateFromUTC(this.slucaj.DatumZadnjegNasilja);
            }

            if (this.slucaj.LiceOdPovjerenjaDatumPruzanjaPomoci != null) {
                this.slucaj.LiceOdPovjerenjaDatumPruzanjaPomoci = formatDateFromUTC(this.slucaj.LiceOdPovjerenjaDatumPruzanjaPomoci);
            }


        },

        closeNotification() {
            this.successMessage = null;
        },

        arrayToString(array) {
            if (array) {
                return array.join(',');
            }
            return '';
        },

        async submitForm() {

            (this.slucaj.DatumRodjenja);
            var submitSlucaj = this.slucaj;
            if (!submitSlucaj.DatumRodjenja) {
                submitSlucaj.DatumRodjenja = null;
            } else {
                var dateUTC = formatDateToUTC(submitSlucaj.DatumRodjenja);

                submitSlucaj.DatumRodjenja = dateUTC;
            }

            if (!submitSlucaj.DatumUlaska) {
                submitSlucaj.DatumUlaska = null;
            } else {
                dateUTC = formatDateToUTC(submitSlucaj.DatumUlaska);
                submitSlucaj.DatumUlaska = dateUTC;
            }

            if (!submitSlucaj.DatumOdlaska) {
                submitSlucaj.DatumOdlaska = null;
            } else {
                dateUTC = formatDateToUTC(submitSlucaj.DatumOdlaska);
                submitSlucaj.DatumOdlaska = dateUTC;
            }

            if (!submitSlucaj.PociniocDatumRodjenja) {
                submitSlucaj.PociniocDatumRodjenja = null;
            } else {
                dateUTC = formatDateToUTC(submitSlucaj.PociniocDatumRodjenja);
                submitSlucaj.PociniocDatumRodjenja = dateUTC;
            }


            if (!submitSlucaj.DatumZadnjegNasilja) {
                submitSlucaj.DatumZadnjegNasilja = null;
            } else {
                dateUTC = formatDateToUTC(submitSlucaj.DatumZadnjegNasilja);
                submitSlucaj.DatumZadnjegNasilja = dateUTC;
            }

            if (!submitSlucaj.LiceOdPovjerenjaDatumPruzanjaPomoci) {
                submitSlucaj.LiceOdPovjerenjaDatumPruzanjaPomoci = null;
            } else {
                dateUTC = formatDateToUTC(submitSlucaj.LiceOdPovjerenjaDatumPruzanjaPomoci);
                submitSlucaj.LiceOdPovjerenjaDatumPruzanjaPomoci = dateUTC;
            }
            if (submitSlucaj.PosljediceNasiljaArray) {
                submitSlucaj.PosljediceNasilja = this.arrayToString(submitSlucaj.PosljediceNasiljaArray);
            }
            if (submitSlucaj.VrstaNasiljaArray) {
                submitSlucaj.VrstaNasilja = this.arrayToString(submitSlucaj.VrstaNasiljaArray);
            }
            if (submitSlucaj.VrstaMjereArray) {
                submitSlucaj.VrstaMjere = this.arrayToString(submitSlucaj.VrstaMjereArray);
            }

            if (submitSlucaj.InstitucijeArray) {
                submitSlucaj.Institucije = this.arrayToString(submitSlucaj.InstitucijeArray);
            }
            if (submitSlucaj.UzrokNasiljaArray) {
                submitSlucaj.UzrokNasilja = this.arrayToString(submitSlucaj.UzrokNasiljaArray);
            }

            if (submitSlucaj.PociniocZavisnostArray) {
                submitSlucaj.PociniocZavisnost = this.arrayToString(submitSlucaj.PociniocZavisnostArray);
            }



            if (!this.slucaj.Ime || !this.slucaj.Tip || !this.slucaj.Prezime || !this.slucaj.DatumUlaska) {
                alert('Popunite obavezna polja (Tip, Ime, Prezime i Datum ulaska u sigurnu kucu)');
                return;
            }

            const token = localStorage.getItem('jwt');
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            if (this.formMode === "create" && !this.isCreatingCase) {

                this.isCreatingCase = true;
                // Call your API to create a new slucaj
                try {

                    await axios.post(apiUrl, submitSlucaj, { headers }
                    ).then(response => {
                        this.successMessage = 'Sačuvani podaci';
                        this.feedback = { message: 'Sačuvani podaci', type: 'success' };
                        this.$emit("submit-success");
                        // const toast = Toast();
                        // toast.success('Case successfully created.');

                        const submittedCaseId = response.data.ID;
                        const editUrl = `/slucaj/edit/${submittedCaseId}`;
                        this.$router.push(editUrl);
                    }); this.$emit("submit-success");

                } catch (error) {
                    console.error("Error creating slucaj:", error);
                } finally {
                    this.isCreatingCase = false;
                }
            } else {
                // Call your API to update the existing slucaj
                try {
                    await axios.put(apiUrl, submitSlucaj, { headers });
                    this.feedback = { message: 'Slucaj azuriran', type: 'success' };
                    this.$emit("submit-success");
                    this.formatSlucaj();
                    this.successMessage = 'Ažurirani podaci';
                } catch (error) {
                    console.error("Error updating slucaj:", error);
                    this.feedback = { message: 'Error updating slucaj', type: 'danger' };
                    // Handle error, e.g., show an error message
                }
            }
        },


        async addChild() {
            if (this.selectedChild) {
                if (!this.slucaj.children) {
                    this.slucaj.children = [this.selectedChild];
                } else {
                    // Check if the selected child is already in the array
                    const isDuplicate = this.slucaj.children.includes(this.selectedChild);
                    if (isDuplicate) {
                        this.duplicateChildWarning = true;
                    } else {
                        this.slucaj.children.push(this.selectedChild);
                    }
                }
                this.selectedChild = '';
            }
        },

        removeChild(childId) {
            const index = this.slucaj.children.indexOf(childId);
            if (index > -1) {
                this.slucaj.children.splice(index, 1);
            }
        },

        getChildName(childId) {
            // Find and return the name of the child based on its ID
            const child = this.childList.find(child => child.ID === childId);
            return child ? child.Ime + ' ' + child.Prezime + ' (' + child.JMBG + ')' : '';
        },
    },
    watch: {
        initialSlucaj(newVal) {
            this.slucaj = newVal || {
                Tip: "",
                Ime: "",
                Prezime: "",
                ImeRoditelja: "",
                DobKorisnika: null,
                MjestoRodjenja: "",
                Pol: 0,
                JMBG: "",
                BrojLK: "",
                Drzavljanstvo: "",
                Adresa: "",
                // RadniStatus: "",
                Invalid: 0,
            };
        },


    },

    computed: {

        filteredchildList() {
            const query = this.searchQuery.toLowerCase().trim();
            if (!query) {
                return this.childList;
            }
            return this.childList.filter((child) =>
                (child.Ime && child.Ime.toLowerCase().includes(query)) ||
                (child.Prezime && child.Prezime.toLowerCase().includes(query)) ||
                (child.DatumRodjenja && child.DatumRodjenja.includes(query)) ||
                (child.JMBG && child.JMBG.includes(query))
            )
        },

        formTitle() {
            return this.formMode === "create" ? "Create Slucaj" : "Edit Slucaj";
        },
        formButton() {
            return this.formMode === "create" ? "Spasi" : "Spasi";
        },

        selectedPol: {

            get() {
                return this.polovi.find(item => item.value === this.initialSlucaj.Pol)?.value;
            },
            set(value) {
                this.slucaj.Pol = value;
            },
        },




    },
    async mounted() {
        try {
            const token = localStorage.getItem('jwt');
            var headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            };
            const response1 = await axios.get(apiUrlLookupList, { headers });
            const lookupData = response1.data;

            this.radniStatusList = lookupData.filter(item => item.name === 'radni_status').map(item => ({ label: item.value, value: item.id }));
            this.tipZrtve = lookupData.filter(item => item.name === 'tip_zrtve').map(item => ({ label: item.value, value: item.id }));
            this.polovi = lookupData.filter(item => item.name === 'pol').map(item => ({ label: item.value, value: item.id }));
            this.dobKorisnika = lookupData.filter(item => item.name === 'dob_korisnika').map(item => ({ label: item.value, value: item.id }));
            this.pociniocNasiljaList = lookupData.filter(item => item.name === 'pocinilac_nasilja').map(item => ({ label: item.value, value: item.id }));
            this.bracniStatusList = lookupData.filter(item => item.name === 'bracni_status').map(item => ({ label: item.value, value: item.id }));
            this.nacinSmjestajaUSigList = lookupData.filter(item => item.name === 'nacin_smjestanja').map(item => ({ label: item.value, value: item.id }));
            this.odlukaONapustanjuList = lookupData.filter(item => item.name === 'odluka_o_napustanju').map(item => ({ label: item.value, value: item.id }));
            this.statusKorisnikaPoIzlaskuList = lookupData.filter(item => item.name === 'status_po_izlasku').map(item => ({ label: item.value, value: item.id }));
            this.nivoObrazovanjaList = lookupData.filter(item => item.name === 'obrazovanje').map(item => ({ label: item.value, value: item.id }));
            this.StambeniStatusList = lookupData.filter(item => item.name === 'stambeni_status').map(item => ({ label: item.value, value: item.id }));
            this.vrstaMjereList = lookupData.filter(item => item.name === 'vrsta_mjere').map(item => ({ label: item.value, value: item.id }));
            this.vrstaNasiljaList = lookupData.filter(item => item.name === 'vrsta_nasilja').map(item => ({ label: item.value, value: item.id }));
            this.trajanjeNasiljaList = lookupData.filter(item => item.name === 'trajanje_nasilja').map(item => ({ label: item.value, value: item.id }));
            this.posljediceNasiljaList = lookupData.filter(item => item.name === 'poslijedice_nasilja').map(item => ({ label: item.value, value: item.id }));
            this.mentalnoOboljenjeList = lookupData.filter(item => item.name === 'mentalno_oboljenje').map(item => ({ label: item.value, value: item.id }));
            this.lijecenaList = lookupData.filter(item => item.name === 'lijecena').map(item => ({ label: item.value, value: item.id }));
            this.institucijeList = lookupData.filter(item => item.name === 'institucije').map(item => ({ label: item.value, value: item.id }));
            this.UzrokNasiljaList = lookupData.filter(item => item.name === 'uzrok_nasilja').map(item => ({ label: item.value, value: item.id }));
            this.PosjedujeOruzijeList = lookupData.filter(item => item.name === 'posjeduje_oruzije').map(item => ({ label: item.value, value: item.id }));
            this.RizikList = lookupData.filter(item => item.name === 'procjena_rizika').map(item => ({ label: item.value, value: item.id }));
            this.PociniocZavisnostList = lookupData.filter(item => item.name === 'zavisnost').map(item => ({ label: item.value, value: item.id }));

            const response2 = await axios.get(apiUrlChild, { headers });

            this.childList = response2.data;
            this.childrenList = response2.data.map(item => ({ label: item.Ime + ' ' + item.Prezime + ' ' + (item.JMBG ? ' ' + item.JMBG : ''), value: item.ID }));
        } catch (error) {
            console.error('Error:', error);
        }
    },
    components: {
        Multiselect,
        DeleteButton,
    },

};

function formatDateToUTC(date) {
    const momentDate = moment(date);
    const utcDate = momentDate.utc(true);
    const formattedDate = utcDate.format('YYYY-MM-DDTHH:mm:ssZ');

    return formattedDate;
}

function formatDateFromUTC(date) {
    const momentDate = moment.utc(date);
    const localDate = momentDate.local(true);
    const formattedDate = localDate.format('YYYY-MM-DD');

    return formattedDate;
}
</script>
<style>
@import url('@vueform/multiselect/themes/default.css');

.custom-bg-div {
    background-color: #ffffff;
    border-top: 0px solid #0091b5;
    border-left-color: #fff;
    border-right-color: #fff;
    color: #000000;
    border-radius: 25px;
}

.hidden-on-screen {
    display: none;
}

/* Styles for printing */
@media print {
    .hidden-on-screen {
        display: block;
        /* or any other appropriate display value for printing */
    }

    .btn.btn-success.mt-1,
    .btn-close,
    .btn.btn-danger.btn-sm,
    .btn.btn-success,
    .btn.btn-secondary,

    .btn.btn-danger {
        display: none;
    }
}

/* No display: none; rule here for @media print */</style>



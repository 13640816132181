<template>
    <div>
      <!-- <h1>Enter a New Case</h1> -->
      <slucaj-form :form-mode="'create'" @submit-success="handleSuccess" />
    </div>
  </template>
  
  <script>
  import SlucajForm from "./SlucajForm.vue";
  
  export default {
    components: {
      SlucajForm,
    },
    methods: {
      handleSuccess() {
        console.log("New case created successfully");
        // Handle success, e.g., show a success message, redirect to another page, etc.
      },
    },
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
    }
  };
  </script>
  
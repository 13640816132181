<template>
    <div id="filter" class="row text-start" style="padding-bottom: 1%;">
        <h2 class="text-start">Izvještaj pruženih usluga po korisniku</h2>
        <div class="col-md-2">
            <br>
            <br>

            <label for="tip" class="col-sm-2 col-form-label">Tip</label>
            <select class="form-control" id="tip" v-model="this.tip">
                <option disabled value="">Odaberite tip</option>
                <option value="Zena">Žena/Djevojka</option>
                <option value="Dijete">Dijete</option>
            </select>
        </div>

        <div class="col-3" v-if="tip === 'Dijete'">
            <br>
            <br>

            <label for="child" class="form-label">Korisnik</label>
            <Multiselect v-model="child" mode="single" :close-on-select="true" :searchable="true" :create-option="false"
                :options="childrenList" />
        </div>
        <div class="col-3" v-if="tip === 'Zena'">
            <br>
            <br>

            <label for="child" class="form-label">Korisnik</label>
            <Multiselect v-model="korisnik" mode="single" :close-on-select="true" :searchable="true"
                :create-option="false" :options="korisnikLista" />
        </div>
        <div class="col-md-2">
            <br>
            <br>

            <label for="DatumKreiranja" class="form-label">Datum pružanja usluge od</label>
            <input type="date" class="form-control" id="DatumKreiranja" v-model="this.DatumKreiranja" required>
        </div>

        <div class="col-md-2">
            <br>
            <br>

            <label for="DatumKreiranjaDo" class="form-label">Datum pružanja usluge do</label>
            <input type="date" class="form-control" id="DatumKreiranjaDo" v-model="this.DatumKreiranjaDo" required>
        </div>
        <div class="col-md-1" style="padding-top: 2%;">
            <br>
            <br>

            <button @click="fetchData" type="button" class="btn btn-success">Filtriraj</button>
        </div>
        <div class="col-md-1" style="padding-top: 2%;">
            <br>
            <br>

            <button @click="printData" type="button" class="btn btn-secondary" id="print"><i
                    class="fa-solid fa-print"></i>
                Print</button>
        </div>
    </div>
    <br>
    <br>

    <div>
        <hr id="divider" class="hr">
        <p v-if="tip === 'Dijete'"> {{ getKid(this.child) }} </p>
        <p v-if="tip === 'Zena'"> {{ getKorisnik(this.korisnik) }} </p>

        
        <table id="tablePrint" class="table table-striped">
            <thead>
                <tr>
                    <th>Tip</th>
                    <th>Usluga</th>
                    <th>Broj pruženih usluga</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in filteredReportData" :key="item.id">
                    <td>{{ item.Tip }}</td>
                    <td>{{ item.Name }}</td>
                    <td>{{ item.Ukupno }}</td>
                </tr>
                <tr>
                    <td colspan="2" style="text-align: right;"><strong>Ukupno pruženih usluga</strong></td>
                    <td><strong>{{ total }}</strong></td>
                </tr>
            </tbody>
        </table>



    </div>
</template>

<script>

import axios from 'axios';
import config from '../../config';
import Multiselect from '@vueform/multiselect'

const apiUrl = `${config.backendUrl}/slucaj/reports`;
const apiSlucaj = `${config.backendUrl}/slucaj`;
const apiUrlLookupList = `${config.backendUrl}/lookup/list`;
const apiKids = `${config.backendUrl}/child`;

export default {
    data() {
        return {
            reportData: [],
            tipFilter: '',
            DatumKreiranja: null,
            DatumKreiranjaDo: null,
            tip: null,
            id: null,
            lookupData: [],
            filteredData: [],
            childrenList: [],
            child: null,
            korisnikLista: [],
            korisnik: null,
        };
    },
    mounted() {
        this.fetchData();
        const token = localStorage.getItem('jwt');
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        axios.get(apiUrlLookupList, { headers })
            .then(response => {
                this.lookupData = response.data.map(item => ({ label: item.value, value: item.id }));

            }).catch(error => {
                console.error('Error:', error);
            });

        axios.get(apiKids, { headers })
            .then(kids => {
                this.childrenList = kids.data.map(item => ({ label: item.Ime + ' ' + item.Prezime + ' ' + (item.JMBG ? ' ' + item.JMBG : ''), value: item.ID }));
            }).catch(error => {
                console.error('Error:', error);
            });

        axios.get(apiSlucaj, { headers })
            .then(korisnik => {
                this.korisnikLista = korisnik.data.map(item => ({ label: item.Ime + ' ' + item.Prezime + ' ' + (item.JMBG ? ' ' + item.JMBG : ''), value: item.ID }));
            }).catch(error => {
                console.error('Error:', error);
            });


    },
    methods: {
        getLabelById(id) {
            const lookupItem = this.lookupData.find(item => item.value === id);
            return lookupItem ? lookupItem.label : '-';
        },
        getKid(id) {
            const child = this.childrenList.find(item => item.value === id);
            return child ? child.label : '-';
        },
        getKorisnik(id) {
            const korisnik = this.korisnikLista.find(item => item.value === id);
            return korisnik ? korisnik.label : '-';
        },
        printData() {
            window.print();
        },
        formatDate(date) {
            if (date) {
                let parsedDate = new Date(date);
                if (!isNaN(parsedDate)) {
                    return parsedDate.toISOString().slice(0, 10);
                }
            }
            return '';
        },

        fetchData() {
            let url = apiUrl + '?report=UslugaPoKorisniku';
            if (this.tip == 'Zena') {
                url += '&tip=Zena&id=' + this.korisnik;
            } else if (this.tip == 'Dijete') {
                url += '&tip=Dijete&id=' + this.child;
            }
            if (this.DatumKreiranja && this.DatumKreiranjaDo) {
                url += `&startDate=${this.DatumKreiranja}&endDate=${this.DatumKreiranjaDo}`;
            }
            const token = localStorage.getItem('jwt');
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            axios.get(url, { headers: headers })
                .then(response => {
                    this.reportData = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
    },
    components: {
        Multiselect,
    },

    computed: {
        filteredReportData() {
            return this.reportData.filter(item => {
                const tipMatch = !this.selectedTip || item.Tip === this.selectedTip;

                return tipMatch;
            });
        },

        total() {
            return this.filteredReportData.reduce((total, item) => total + item.Ukupno, 0);
        },
    },

};

</script>

<style>
@media print {
    #menuBar {
        display: none;
    }

    #divider {
        display: none;
    }

    @page {
        margin-bottom: 2.3cm;
        /* adjust as needed */
    }

    #tablePrint {
        bottom: 10px;
    }

    #printButton {
        left: -9999px;
        width: 1px;
        height: 1px;
        visibility: hidden;
    }



    #header {
        display: none;

    }

    #filter {
        display: none;

    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    table {
        width: 100%;
        /* Adjust as needed */
    }

    th,
    td {
        overflow: hidden;
        padding-left: 10px;
    }
}

.custom-print-button {
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    /* Add other custom styles as needed */
}

body {
    margin: 0;
    padding: 0;
}
</style>
<template>
    <div>

        <slucaj-form :form-mode="'edit'" :initial-slucaj="slucaj" :edit-mode="true" @submit-success="handleSuccess" />

    </div>
</template>
  
<script>
import SlucajForm from "./SlucajForm.vue";
import axios from "axios";
import config from "../config";
import moment from 'moment';

const apiUrl = `${config.backendUrl}/slucaj`;

function formatDateFromUTC(date) {
    // Create a moment object from the input date
    const momentDate = moment.utc(date);

    // Convert the date to the user's local timezone
    const localDate = momentDate.local(true);

    // Format the date as "YYYY-MM-DD"
    const formattedDate = localDate.format('YYYY-MM-DD');

    // Return the formatted date
    return formattedDate;
}

export default {
    components: {
        SlucajForm,
    },
    data() {
        return {
            slucaj: {
                ID: '',
                Tip: "",
                Ime: "",
                Prezime: "",
                ImeRoditelja: "",
                // datm rodjenja
                DobKorisnika: null,
                MjestoRodjenja: "",
                Pol: 0,
                JMBG: "",
                BrojLK: "",
                Adresa: "",
                Drzavljanstvo: "",
                Invalid: -1,
                //Djeca: -1,
                DjeteSaPosljedicamaNasilja: -1,
                RelacijaSaPociniocem: 0,
                BracniStatus: 0,
                BrojDjece: 0,
                RadnoIskustvo: "",
                DjecaUSigurnoj: -1,
                NacinSmjestajaUSig: 0,
                OdlukaONapustanju: 0,
                StatusKorisnikaPoIzlasku: 0,
                NivoObrazovanja: 0,
                LicniPrihodi: '',
                LicnaImovina: '',
                PripadnikDrustveneSkupine: 0,
                StambeniStatus: null,
                Komentar: "",
                TrajanjeMjere: "",
                KomentarMjere: "",
                PosljediceNasiljaArray: [],
                PosljediceNasilja: '',
                VrstaNasiljaArray: [],
                VrstaNasilja: '',
                VrstaMjere: null,
                VrstaMjereArray: [],
                InstitucijeArray: [],
                Institucije: '',
                UzrokNasilja: null,
                UzrokNasiljaArray: [],
                PociniocZavisnost: null,
                PociniocZavisnostArray: [],
                PomocPsiholoska: '',
                PomocPsiholoskaArray: [],
                PomocPsiholoskaKomentar: '',
                PomocSocijalna: '',
                PomocSocijalnaArray: [],
                PomocSocijalnaKomentar: '',
                InvalidOpisno: "",
                BrojDjeceUsigurnojKuci: null,
                PrijetiUbistvom: null,
                PrijetiSamoubistvom: null,

                PomocPravna: '',
                PomocPravnaArray: [],
                PomocPravnaKomentar: '',
                PomocZdravstvena: '',
                PomocZdravstvenaArray: [],
                PomocZdravstvenaKomentar: '',
                PlanIntegracije: '',
                PlanIntegracijeArray: [],
                ZlostavljaniKaoDijetePocinioc: null,
                OpojnaSredstva: null,
                OpojnaSredstvaPocinioc: null,
                ZlostavljniKaoDijete: null,
                DatumUlaska :null,
                DatumOdlaska: null,

            },
            editMode: true,
        }
    },

    props: ['slucajId'],
    async mounted() {
        try {
            const token = localStorage.getItem("jwt");
            const response = await axios.get(`${apiUrl}?ID=${this.$route.params.slucajId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            this.slucaj = response.data;
            if (this.slucaj.DatumRodjenja != null) {
                this.slucaj.DatumRodjenja = formatDateFromUTC(this.slucaj.DatumRodjenja);
            }
            if (this.slucaj.DatumUlaska != null) {
                this.slucaj.DatumUlaska = formatDateFromUTC(this.slucaj.DatumUlaska);
            }
            if (this.slucaj.DatumOdlaska != null) {
                this.slucaj.DatumOdlaska = formatDateFromUTC(this.slucaj.DatumOdlaska);
            }

            if (this.slucaj.PociniocDatumRodjenja != null) {
                this.slucaj.PociniocDatumRodjenja = formatDateFromUTC(this.slucaj.PociniocDatumRodjenja);
            }
            if (this.slucaj.DatumZadnjegNasilja != null) {
                this.slucaj.DatumZadnjegNasilja = formatDateFromUTC(this.slucaj.DatumZadnjegNasilja);
            }

            if (this.slucaj.LiceOdPovjerenjaDatumPruzanjaPomoci != null) {
                this.slucaj.LiceOdPovjerenjaDatumPruzanjaPomoci = formatDateFromUTC(this.slucaj.LiceOdPovjerenjaDatumPruzanjaPomoci);
            }
            // need to check if this exitst to format ti only then TODO
            if (this.slucaj.PosljediceNasilja) {
                this.slucaj.PosljediceNasiljaArray = this.slucaj.PosljediceNasilja.split(',');
            }
            if (this.slucaj.VrstaNasilja) {
                this.slucaj.VrstaNasiljaArray = this.slucaj.VrstaNasilja.split(',');
            }
            if (this.slucaj.VrstaMjere) {
                this.slucaj.VrstaMjereArray = this.slucaj.VrstaMjere.split(',');
            }

            if (this.slucaj.Institucije) {
                this.slucaj.InstitucijeArray = this.slucaj.Institucije.split(',');
            }
            if (this.slucaj.UzrokNasilja) {
                this.slucaj.UzrokNasiljaArray = this.slucaj.UzrokNasilja.split(',');
            }
            if (this.slucaj.PociniocZavisnost)
                this.slucaj.PociniocZavisnostArray = this.slucaj.PociniocZavisnost.split(',');
            if (this.slucaj.PomocPsiholoska)
                this.slucaj.PomocPsiholoskaArray = this.slucaj.PomocPsiholoska.split(',');
            if (this.slucaj.PomocSocijalna)
                this.slucaj.PomocSocijalnaArray = this.slucaj.PomocSocijalna.split(',');

            if (this.slucaj.PomocPravna)
                this.slucaj.PomocPravnaArray = this.slucaj.PomocPravna.split(',');
            if (this.slucaj.PomocZdravstvena)
                this.slucaj.PomocZdravstvenaArray = this.slucaj.PomocZdravstvena.split(',');
            if (this.slucaj.PlanIntegracije)
                this.slucaj.PlanIntegracijeArray = this.slucaj.PlanIntegracije.split(',');

        } catch (error) {
            console.error("Error fetching slucaj:", error);
        }
    },

    methods: {
        handleSuccess() {
            console.log("Case updated successfully");
            // Handle success, e.g., show a success message, redirect to another page, etc.

        },
    },
};
</script>
  
<template>
    <h2 class="text-start bg-light p-2">LICE OD POVJERENJA</h2>

    <div class="container custom-dashboard" style="border:1px solid #b7b7b7; width: 100%; border-radius: 25px">
        <b><br>
        </b>
        <br>

        <form @submit.prevent="submitForm">
            <div class="row text-start">
                <div class="col-md-4">
                    <div class="mb-3">
                        <label for="Ime" class="form-label">Ime</label>
                        <input type="text" class="form-control" id="Ime" v-model="personTrust.Ime" required tabindex="1" />
                    </div>

                    <div class="mb-3">
                        <label for="Institucija" class="form-label">Institucija u kojoj je vršena usluga</label>
                        <select class="form-control" id="Institucija" v-model="selectedInstitucija" required tabindex="4">
                            <option disabled value="">Odaberite</option>
                            <option v-for="status in InstitucijaList" :key="status.value" :value="status.value">
                                {{ status.label }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="mb-3">
                        <label for="Prezime" class="form-label">Prezime</label>
                        <input type="text" class="form-control" id="Prezime" v-model="personTrust.Prezime" required
                            tabindex="1" />
                    </div>
                    <div class="mb-3">
                        <label for="FazaPostupka" class="form-label">Faza postupka</label>
                        <select class="form-control" id="FazaPostupka" v-model="selectedFazaPostupka" required tabindex="4">
                            <option disabled value="">Odaberite</option>
                            <option v-for="status in FazaPostupkaList" :key="status.value" :value="status.value" required>
                                {{ status.label }}
                            </option>
                        </select>
                    </div>

                </div>
                <div class="col-md-4 rounded">
                    <div class="mb-3">

                        <label for="ZrtvaNasilja" class="form-label">Zrtva nasilja</label>
                        <Multiselect v-model="selectedZrtva" mode="single" :searchable="true" :close-on-select="true"
                            tabindex="3" :options="slucajList"
                            placeholder="Pretražite po imenu, prezimenu ili matičnom broju..." />
                    </div>

                    <div class="mb-3">
                        <label for="DatumPruzanjaPomoci" class="form-label">Datum pružanja pomoći</label>
                        <input type="date" class="form-control" id="DatumPruzanjaPomoci" required tabindex="5"
                            v-model="personTrust.DatumPruzanjaPomoci" />
                    </div>

                </div>

                <div class="col-md-8">

                    <div class="mb-3">
                        <label for="OpisPomoci" class="form-label">Opis pružene pomoći</label>
                        <textarea v-model="personTrust.OpisPomoci" placeholder="Opis pružene pomoći" class="form-control"
                            tabindex="6" rows="5" style="width: 100%;"></textarea>
                    </div>
                </div>
            </div>

            <br>



        </form>


        <div class="row">
            <div class="col-md-1">
                <br>
                <button type="button" @click="submitForm" class="btn btn-success">{{ editMode ? 'Uredi' : 'Kreiraj'
                }}</button>
            </div>
            <div class="col-md-1" v-if="editMode">
                <br>
                <DeleteButton @delete="deleteService"></DeleteButton>
            </div>
            <div class="col-md-3">
                <div v-if="successMessage" class="alert alert-success alert-dismissible mt-3">
                    {{ successMessage }}
                    <button type="button" class="btn-close" aria-label="Close" @click="closeNotification"></button>
                </div>
            </div>
            <br>



        </div>
        <br>



    </div>
    <br><br><br>
    <br><br><br>
    <br><br><br>
</template>
<script>
import config from '../config'
import axios from 'axios';
import moment from 'moment';
import Multiselect from '@vueform/multiselect'
import DeleteButton from '@/components/DeleteButton.vue';

const apiUrl = `${config.backendUrl}/personTrust`;
const apiUrlLookupList = `${config.backendUrl}/lookup/list`;
const apiUrlSlucaj = `${config.backendUrl}/slucaj`;

const token = localStorage.getItem('jwt');
var headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
};

export default {
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            personTrust: {
                Ime: '',
                Prezime: '',
                DatumPruzanjaPomoci: null,
                Institucija: null,
                FazaPostupka: null,
                OpisPomoci: null,
                SlucajID: null,
            },
            InstitucijaList: [],
            FazaPostupkaList: [],
            successMessage: '',
            slucajList: [],
            id: null,
        };
    },
    computed: {
        selectedInstitucija: {
            get() {
                return this.InstitucijaList.find(item => item.value === this.personTrust.Institucija)?.value;
            },
            set(value) {
                this.personTrust.Institucija = value;
            },
        },
        selectedFazaPostupka: {
            get() {
                return this.FazaPostupkaList.find(item => item.value === this.personTrust.FazaPostupka)?.value;
            },
            set(value) {
                this.personTrust.FazaPostupka = value;
            },
        },
        selectedZrtva: {
            get() {
                return this.personTrust.SlucajID;
            },
            set(value) {
                this.personTrust.SlucajID = value ? value : null;
            },
        },

    },

    async mounted() {
        const token = localStorage.getItem('jwt');
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        if (this.$route.params.personID) {
            this.id = this.$route.params.personID;
        }
        await axios.get(apiUrlLookupList, { headers })
            .then(response => {
                const lookupData = response.data;
                this.InstitucijaList = lookupData.filter(item => item.name === 'institucije_usluge').map(item => ({ label: item.value, value: item.id }));
                this.FazaPostupkaList = lookupData.filter(item => item.name === 'faza_postupka_lice').map(item => ({ label: item.value, value: item.id }));
            }).catch(error => {
                console.error('Error:', error);
            });

        try {
            const token = localStorage.getItem('jwt');
            const response2 = await axios.get(apiUrlSlucaj, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response2.data) {
                this.slucajList = response2.data.map(item => ({
                    label: item.Ime + ' ' + item.Prezime + ' ' + (item.JMBG ? ' ' + item.JMBG : ''),
                    value: item.ID
                }));
            }
        } catch (error) {
            console.error('Error fetching slucaj list:', error);
        }
        if (this.id) {
            try {
                const token = localStorage.getItem('jwt');
                const response3 = await axios.get(`${apiUrl}?id=${this.id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response3.data) {
                    this.personTrust = response3.data;
                    this.personTrust.DatumPruzanjaPomoci = formatDateFromUTC(this.personTrust.DatumPruzanjaPomoci);
                    this.selectedZrtva = this.personTrust.SlucajID;

                }
            } catch (error) {
                console.error('Error fetching slucaj list:', error);
            }
        }


    },
    methods: {
        submitForm() {
            if (!this.personTrust.DatumPruzanjaPomoci) {
                this.personTrust.DatumPruzanjaPomoci = null;
            } else {
                const dateUTC = formatDateToUTC(this.personTrust.DatumPruzanjaPomoci);
                this.personTrust.DatumPruzanjaPomoci = dateUTC;
            }

            if (this.editMode) {
                this.personTrust.id = parseInt(this.id);
                this.updatePersonTrust(this.personTrust);
                this.personTrust.DatumPruzanjaPomoci = formatDateFromUTC(this.personTrust.DatumPruzanjaPomoci);

            } else {
                this.createPersonTrust(this.personTrust)
                    .then(id => {
                        this.id = id;
                        console.log(this.id); // Access the ID value here
                        this.personTrust.DatumPruzanjaPomoci = formatDateFromUTC(this.personTrust.DatumPruzanjaPomoci);
                        this.personTrust.SlucajID = this.selectedZrtva.value;
                        this.$router.push({ name: 'PersonOfTrustEdit', params: { personID: this.id } });
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error creating Person Trust:', error);
                    });
            }
        },

        deleteService() {
            const token = localStorage.getItem('jwt');
            var headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            };
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${apiUrl}?id=` + this.id, { headers })
                    .then(response => {
                        // Handle successful creation
                        console.log('Delted id ' + this.id + response.data);
                        this.$router.push({ name: 'PersonOfTrustList' });
                    })
                    .catch(error => {
                        // Handle error during creation
                        this.errorMessage = "Greska prilikom brisanja"

                        console.error('Error creating Person Trust:', error);
                        reject(error);
                    });
            });
        },

        resetForm() {
            this.personTrust = {
                Ime: '',
                Prezime: '',
                DatumPruzanjaPomoci: null,
                Institucija: null,
                FazaPostupka: null,
                OpisPomoci: null,
            };
        },


        createPersonTrust(submitpersonTrust) {
            const token = localStorage.getItem('jwt');
            var headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            };
            return new Promise((resolve, reject) => {
                axios
                    .post(apiUrl, submitpersonTrust, { headers })
                    .then(response => {
                        // Handle successful creation
                        const id = response.data.ID;
                        resolve(id);
                    })
                    .catch(error => {
                        // Handle error during creation
                        console.error('Error creating Person Trust:', error);
                        reject(error);
                    });
            });
        },
        updatePersonTrust(submitpersonTrust) {
            axios
                .put(`${apiUrl}`, submitpersonTrust, { headers })
                .then(response => {
                    // Handle successful update
                    console.log('Person Trust updated:', response.data);
                    this.successMessage = "Ažurirani podaci"
                    // Perform any necessary actions after successful update (e.g., show success message, redirect)
                })
                .catch(error => {
                    // Handle error during update
                    console.error('Error updating Person Trust:', error);
                    // Perform any necessary error handling (e.g., show error message)
                });
        },
        closeNotification() {
            this.successMessage = null;
        },
    },



    components: {
        Multiselect,
        DeleteButton,
    },


};

function formatDateToUTC(date) {
    // Create a moment object from the input date
    const momentDate = moment(date);

    // Convert the date to UTC
    const utcDate = momentDate.utc(true);

    // Format the date as "YYYY-MM-DDTHH:mm:ssZ"
    const formattedDate = utcDate.format('YYYY-MM-DDTHH:mm:ssZ');
    // const formattedDate = moment(dateString).format('YYYY-MM-DD');


    // Return the formatted date
    return formattedDate;
}

function formatDateFromUTC(date) {
    // Create a moment object from the input date

    const momentDate = moment.utc(date);

    // Convert the date to the user's local timezone
    const localDate = momentDate.local(true);

    // Format the date as "YYYY-MM-DD"
    const formattedDate = localDate.format('YYYY-MM-DD');

    // Return the formatted date
    return formattedDate;
}

</script>
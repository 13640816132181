<template>
    <div>
        <h2>Pregled djece</h2>
        <br>
        <div class="mb-3">
            <input type="text" class="form-control" placeholder="Pretrazi..." v-model="searchQuery" />
        </div>

        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Uredi</th>
                    <th>Ime</th>
                    <th>Prezime</th>
                    <th>JMBG</th>
                    <th>Roditelj</th>
                    <th>Adresa</th>
                    <th>Datum Rođenja</th>
                    <th>Datum unosa</th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="slucaj in filteredchildList" :key="slucaj.ID" @click="goToCaseDetails(slucaj)">
                    <td style="width: 1%"><i class="fa-solid fa-user-pen"></i></td>
                    <td style="width: 10%">{{ slucaj.Ime }}</td>
                    <td style="width: 10%">{{ slucaj.Prezime }}</td>
                    <td style="width: 10%">{{ slucaj.JMBG }}</td>
                    <td style="width: 10%">{{ slucaj.Roditelj }}</td>
                    <td style="width: 10%">{{ slucaj.Adresa }}</td>

                    <td style="width: 10%">{{ formatDate(slucaj.DatumRodenja) }}</td>
                    <td style="width: 10%">{{ formatDate(slucaj.DateCreated) }}</td>

                </tr>
                <tr v-if="filteredchildList.length === 0">
                    <td colspan="12" class="text-center">Nema podataka</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script>
import axios from 'axios';
import config from '../config';

const apiUrl = `${config.backendUrl}/child`;

export default {
    name: 'ChildPageList',
    data() {
        return {
            childList: [],
            searchQuery: '',
        };
    },
    async mounted() {
        try {
            const token = localStorage.getItem('jwt');
            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            this.childList = response.data;
        } catch (error) {
            console.error('Error fetching child list:', error);
        }
    },
    computed: {
        filteredchildList() {
            const query = this.searchQuery.toLowerCase().trim();
            if (!query) {
                return this.childList;
            }

            const searchTerms = query.split(' ');
            return this.childList.filter(child => {
                const firstName = child.Ime ? child.Ime.toLowerCase() : '';
                const lastName = child.Prezime ? child.Prezime.toLowerCase() : '';

                // Check if each search term is present in any searchable field
                return searchTerms.every(term =>
                    firstName.includes(term) ||
                    lastName.includes(term) ||
                    (child.DatumRodenja && child.DatumRodenja.includes(term)) ||
                    (child.Adresa && child.Adresa.toLowerCase().includes(term)) ||
                    (child.Roditelj && child.Roditelj.toLowerCase().includes(term)) ||
                    (child.DateCreated && child.DateCreated.includes(term)) ||

                    (child.JMBG && child.JMBG.includes(term))
                );
            });
        },


    },
    methods: {
        goToCaseDetails(child) {
            this.$router.push({ name: 'ChildPageEdit', params: { childID: child.ID, isEditMode: true } });
        },
        formatDate(date) {
            if (date) {
                let parsedDate = new Date(date);
                if (!isNaN(parsedDate)) {
                    return parsedDate.toISOString().slice(0, 10);
                }
            }
            return ''; // or return a placeholder string like 'N/A' or 'Unknown'
        },
    },
};


</script>
  
<style>
tbody tr:hover {
    cursor: pointer;
    background-color: #f5f5f5;
}
</style>
<template>
  <div class="position-relative">
    <div class="col-md-4">
      <h2 class="">Pregled korisnika</h2>
      <div class="border-top border-secondary my-4"></div>

      <table class="table table-striped mt-3">
        <thead>
          <tr>
            <th>Uredi</th>

            <th>Korisničko ime</th>
            <th>Ime</th>
            <th>Prezime</th>
            
          </tr>
        </thead>
        <tbody>
          <template v-if="userList.length > 0">
            <tr v-for="(user, index) in userList" :key="user.id" @click="goToCaseDetails(user)"
              :class="{ 'even-row': index % 2 === 0 }">
              <td style="width: 1%"><i class="fa-solid fa-user-pen"></i></td>
              <td>{{ user.username }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.lastname }}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="3" class="text-center">No users found.</td>
            </tr>
          </template>
        </tbody>
      </table>


      <router-link :to="{ name: 'UserPage' }" class="btn btn-success mt-1">
        <i class="fas fa-sign-in-alt fa-fw me-2"></i>
        <span>Dodaj novog korisnika</span>
      </router-link>

    </div>
  </div>
</template>


<script>
import axios from 'axios';
import config from '../config';

export default {
  data() {
    return {
      userList: [],
    };
  },
  mounted() {
    const token = localStorage.getItem('jwt');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    axios.get(`${config.backendUrl}/users/list`, { headers })
      .then(response => {
        this.userList = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    goToCaseDetails(user) {
      this.$router.push({ name: 'UserPageEdit', params: { username: user.username, editMode: true } });
    },
  }
};
</script>

<style scoped>
.even-row {
  background-color: #ffffffdb; /* Replace with your desired color */
}
</style>

<template>
  <!-- Sidebar -->


  <nav id="sidebarMenu" class="collapse d-lg-block sidebar collapse custom-sidebar text-start">
    <div>
      <img src="./logo.svg" style="width: 80%; height: auto; padding-left: 20%; opacity: 0.7;" alt="Logo">
    </div>

    <br><br>

    <div class="position-sticky">
      <div class="list-group list-group-flush menu-container">
        <BouncingIconLink to="/service" icon="fa-solid fa-hand-holding-medical" label=" Unos usluge" animation="fa-bounce">
        </BouncingIconLink>
        <BouncingIconLink to="/service_kids" icon="fa-solid fa-hand-holding-medical" label=" Unos usluge za dijete" animation="fa-bounce">
        </BouncingIconLink>
        <BouncingIconLink to="/person_of_trust" icon="fa-solid fa-user-shield" label=" Lica od povjerenja"
          animation="fa-bounce"></BouncingIconLink>
        <BouncingIconLink to="/new-case" icon="fa-solid fa-hand-holding-heart" label=" Unos korisnika"
          animation="fa-bounce"></BouncingIconLink>
        <BouncingIconLink to="/child-create" icon="fa-solid fa-child-reaching" label=" Unos dijeteta"
          animation="fa-bounce"></BouncingIconLink>
        <BouncingIconLink :to="{ name: 'UserPage' }" icon="fa-user-plus" label=" Unos korisnika aplikacije" animation="fa-beat">
        </BouncingIconLink>
        <!-- </div> -->
        <div class="divider"></div>
        <p class="text-center">Pregledi</p>

        <!-- <hr class="hr hr-blurry" /> -->
        <!-- <p style="text-align: center;">Pregledi</p> -->
        <!-- <div class="position-flex"> -->
          <BouncingIconLink to="/service/list" icon="fa-solid fa-hand-holding-medical" label=" Pregled usluga" animation="fa-bounce">
        </BouncingIconLink>
        <BouncingIconLink to="/slucaj-list" icon="fa-solid fa-magnifying-glass" label=" Pregled korisnika"
          animation="fa-flip"></BouncingIconLink>
        <BouncingIconLink to="/child-list" icon="fa-solid fa-children" label=" Pregled djece" animation="fa-flip">
        </BouncingIconLink>
        <BouncingIconLink :to="{ name: 'UserList', params: { username: user } }" icon="fa-solid fa-users"
          label=" Pregled usera" animation="fa-flip"></BouncingIconLink>
        <BouncingIconLink to="/person_of_trust_view" icon="fa-solid fa-user-shield" label=" Lica od povjerenja"
          animation="fa-flip"></BouncingIconLink>

       
          <BouncingIconLink to="/reports/zrtve_v2" icon="fa-solid fa-chart-simple" label=" Izvještaj provedenih dana u sigurnoj kući" animation="fa-bounce"
          > </BouncingIconLink>
       
          <BouncingIconLink to="/reports/zrtve_v4" icon="fa-solid fa-chart-simple" label=" Izvještaj broj korisnika" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/usluge" icon="fa-solid fa-chart-simple" label=" Izvještaj usluga" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/usluge_po_korisniku" icon="fa-solid fa-chart-simple" label=" Izvještaj usluga po korisniku" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/vrsta_mjere" icon="fa-solid fa-chart-simple" label=" Izvještaj izrečenih mjera" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/bracni_status" icon="fa-solid fa-chart-simple" label=" Izvještaj bračnog statusa" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/dob_korisnika" icon="fa-solid fa-chart-simple" label=" Izvještaj doba korisnika" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/relacija_sa_pocinicom" icon="fa-solid fa-chart-simple" label=" Izvještaj relacije sa počiniocom" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/stambeni_status" icon="fa-solid fa-chart-simple" label=" Izvještaj stambenog statusa" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/obrazovanje" icon="fa-solid fa-chart-simple" label=" Izvještaj nivoa obrazovanja" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/mentalno" icon="fa-solid fa-chart-simple" label=" Izvještaj mentalnog oboljenja" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/oruzije" icon="fa-solid fa-chart-simple" label=" Izvještaj posjedovanja oružija" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/rizik" icon="fa-solid fa-chart-simple" label=" Izvještaj rizika" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/trajanje_nasilja" icon="fa-solid fa-chart-simple" label=" Izvještaj trajanja nasilja" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/vrsta_nasilja" icon="fa-solid fa-chart-simple" label=" Izvještaj vrste nasilja" animation="fa-bounce"
          > </BouncingIconLink>
          <BouncingIconLink to="/reports/uzrok_nasilja" icon="fa-solid fa-chart-simple" label=" Izvještaj uzroka nasilja" animation="fa-bounce"
          > </BouncingIconLink>
          
          
        
          
        <!-- <p style="text-align: center;">Postavke</p> -->
        <!-- </div> -->
        <div class="divider"></div>
        <p class="text-center">Postavke</p>
        <BouncingIconLink :to="{ name: 'LookupManagment', params: { lookupName: 'pol' } }" icon="fa-solid fa-list-ul"
          label=" Sifarnik"></BouncingIconLink>
        <BouncingIconLink :to="{ name: 'UserPageEdit', params: { username: user } }" icon="fa-solid fa-gear"
          animation="fa-spin" label=" Postavke korisnika"></BouncingIconLink>
        <BouncingIconLink to="/login" icon="fa-sign-in-alt" label=" Izlaz" animation="fa-fade" @click="logout">
        </BouncingIconLink>


      </div>
    </div>
  </nav>
  <!-- Sidebar -->
  <link href="../" rel="stylesheet">
</template>


<script>
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import "../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.css";
import "../../node_modules/@fortawesome/fontawesome-free/css/brands.css";
import "../../node_modules/@fortawesome/fontawesome-free/css/solid.css";
import BouncingIconLink from "./BoucingIconLink.vue";

//import 'bootstrap/dist/js/bootstrap.bundle'z

export default {
  name: "AppNavbar",
  data() {
    return {
      isDropdownOpen: true,
      user: localStorage.getItem('username'),
      isHovered: false,

    };
  },
  methods: {
    logout() {
      localStorage.removeItem("jwt");
      this.$router.push("/login"); // Redirect to the login page
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
  },

  components: {
    BouncingIconLink
  }
};
</script>

<style scoped>
body {
  background-color: #fbfbfb;
}

@media (min-width: 991.98px) {
  main {
    padding-left: 240px;
  }
}

.divider {
  height: 6px;
  background-color: #ffffffc5;
  /* Set the desired color for the divider */
  /* margin-top: 2px; Adjust the spacing as needed */
  /* margin-bottom: 2px; */
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 58px 0 0;
  /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  z-index: 600;
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
  }
}


.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}


.list-group-item.active {
  background-color: #9d869e;
  /* background-color: #0f8971; */

  /* 0f8971 */
  color: #ffffff;
  /* Replace with your desired text color */
  border-color: transparent;
  /* Remove the border color */
}

.list-group-item,
.custom-sidebar {
  background-color: #996887;
  /* #86c0abad */
  border-top: 0px solid #0091b5;
  border-left-color: #fff;
  border-right-color: #fff;
  color: #ffffff;
}

header h5 {
  font-size: 30px;
  font-weight: 600;
  background-image: linear-gradient(45deg, #553c9a, #ee4b2b);

  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;

}

.list-group-item:hover {
  background-color: #9d869e;
  color: #ffffff;

}
.menu-container {
  max-height: 600px; /* Adjust the height as needed */
  overflow-y: auto;
}
</style>
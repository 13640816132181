<template>
    <h2 class="text-start bg-light p-2">Pružena usluga</h2>

    <div class="container custom-dashboard" style="border:1px solid #b7b7b7; width: 100%; border-radius: 25px">
        <b><br>
        </b>
        <br>

        <form @submit.prevent="submitForm">
            <div class="row text-start">
                <div class="row">

                    <div class="col-md-8">
                        <div class="mb-3">
                            <label for="ZrtvaNasilja" class="form-label">Korisnik</label>
                            <Multiselect v-model="selectedZrtva" mode="single" :searchable="true" :close-on-select="true"
                                tabindex="1" :options="slucajList"
                                placeholder="Pretražite po imenu, prezimenu ili matičnom broju..." />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-12">
                            <label for="DatumPruzanjaPomoci" class="form-label">Datum pružanja usluge</label>
                            <input type="date" class="form-control" id="DatumPruzanjaPomoci" required tabindex="5"
                                v-model="helpProvided.DatumPruzanjaPomoci" />
                        </div>
                    </div>

                </div>
                <div class="row">

                </div>
                <div class="col-md-12">
                    <label for="TipPomociList" class="form-label">Tip pružene pomoći</label>
                    <Multiselect v-model="TipPomociListArray" mode="tags" :close-on-select="false" :searchable="true"
                        :create-option="false" :options=TipPomociList tabindex="2" />
                </div>

            </div>


            <div class="col-md-8">

                <div class="mb-3">
                    <label for="Komentar" class="form-label">Opis pružene pomoći</label>
                    <textarea v-model="helpProvided.Komentar" placeholder="Opis pružene pomoći" class="form-control"
                        tabindex="6" rows="5" style="width: 100%;"></textarea>
                </div>
            </div>


            <br>



        </form>

        <div class="row">
            <div class="col-md-1">
                <br>
                <button type="submit" @click="submitForm"  :disabled="!selectedZrtva" class="btn btn-success">{{ editMode ?
                    'Uredi' : 'Kreiraj'
                }}</button>
                <br>
                <br>
            </div>

            <div class="col-md-2" v-if="editMode">
                <br>
                <DeleteButton @delete="deleteService"></DeleteButton>
                <br>
            </div>
            <div class="col-md-2">
                        <div v-if="successMessage" class="alert alert-success alert-dismissible mt-3"
                            style="max-width: 20%; padding-right: 100%;">
                            {{ successMessage }}
                            <button type="button" class="btn-close" aria-label="Close" @click="closeNotification"></button>
                        </div>
                    </div>
        </div>


    </div>
</template>
<script>
import config from '../config'
import axios from 'axios';
import moment from 'moment';
import Multiselect from '@vueform/multiselect'
import DeleteButton from '@/components/DeleteButton.vue';

const apiUrl = `${config.backendUrl}/service`;
const apiUrlLookupList = `${config.backendUrl}/lookup/pomoc`;
const apiUrlSlucaj = `${config.backendUrl}/slucaj`;



export default {
    editMode: false,
    kidmode: false,

    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mode: null,
            helpProvided: {
                DatumPruzanjaPomoci: null,
                TipPomoci: '',
                Komentar: null,
                SlucajID: null,
            },
            TipPomociList: [],
            successMessage: '',
            errorMessage: '',
            slucajList: [],
            childList: [],

            TipPomociListArray: [],
            id: null,
            isCreating: false,
        };
    },
    computed: {
        selectedZrtva: {
            get() {
                return this.helpProvided.SlucajID;
            },
            set(value) {
                this.helpProvided.SlucajID = value ? value : null;
            },
        },

        minDate() {
            // Compute the minimum allowed date for DatumOdlaska based on DatumDolaska
            return this.helpProvided.DatumDolaska;
        }
    },

    async mounted() {
        if (this.$route.params.helpProvidedID) {
            this.id = this.$route.params.helpProvidedID;
        }
        const token = localStorage.getItem('jwt');
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        await axios.get(apiUrlLookupList, { headers })
            .then(response => {
                const lookupData = response.data;
                this.TipPomociList = lookupData.map(item => ({ label: item.opisno, value: item.id }));
            }).catch(error => {
                console.error('Error:', error);
            });

        try {

            const response2 = await axios.get(apiUrlSlucaj, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response2.data) {
                this.slucajList = response2.data.map(item => ({
                    label: item.Ime + ' ' + item.Prezime + ' ' + (item.JMBG ? ' ' + item.JMBG : ''),
                    value: item.ID
                }));
            }
        } catch (error) {
            console.error('Error fetching slucaj list:', error);
        }

        if (this.editMode) {
            try {
                const response3 = await axios.get(`${apiUrl}?id=${this.id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log(response3)
                if (response3.data) {
                    this.helpProvided = response3.data;
                    this.helpProvided.DatumPruzanjaPomoci = formatDateFromUTC(this.helpProvided.DatumPruzanjaPomoci);
                    this.helpProvided.DatumDolaska = formatDateFromUTC(this.helpProvided.DatumDolaska);
                    this.helpProvided.DatumOdlaska = formatDateFromUTC(this.helpProvided.DatumOdlaska);
                    this.selectedZrtva = this.helpProvided.SlucajID;

                    if (this.helpProvided.TipPomoci) {
                        this.TipPomociListArray = this.helpProvided.TipPomoci.split(',');
                    }
                }
            } catch (error) {
                console.error('Error fetching helpProvided list:', error);
            }
        }


    },
    methods: {

        splitSelectedValue() {
            const selectedValue = this.selectedZrtva;
            const lines = selectedValue.split("/");

            this.selectedZrtva = lines.join("<br>");
        },

        arrayToString(array) {
            console.log("ide22")
            if (array) {
                return array.join(',');
            }
            return '';
        },
        async submitForm() {
            if (!this.helpProvided.DatumPruzanjaPomoci) {
                this.helpProvided.DatumPruzanjaPomoci = null;
            } else {
                const dateUTC = formatDateToUTC(this.helpProvided.DatumPruzanjaPomoci);
                this.helpProvided.DatumPruzanjaPomoci = dateUTC;
            }



            if (this.TipPomociListArray) {
                this.helpProvided.TipPomoci = this.arrayToString(this.TipPomociListArray);
            }
            if (this.helpProvided.DatumOdlaska == "Invalid date") {
                console.log('invalid upao');
                this.helpProvided.DatumOdlaska = null;

            }
            if (this.editMode) {
                this.helpProvided.id = parseInt(this.id);
                this.updatehelpProvided(this.helpProvided);
                this.helpProvided.DatumPruzanjaPomoci = formatDateFromUTC(this.helpProvided.DatumPruzanjaPomoci);
                this.helpProvided.DatumDolaska = formatDateFromUTC(this.helpProvided.DatumDolaska);
                this.helpProvided.DatumOdlaska = formatDateFromUTC(this.helpProvided.DatumOdlaska);
            } else {
                try {
                    this.isCreating = true;
                    console.error(this.isCreating);
                    await this.createhelpProvided(this.helpProvided)
                        .then(id => {
                            this.id = id;
                            console.log(this.id);
                            this.helpProvided.DatumPruzanjaPomoci = formatDateFromUTC(this.helpProvided.DatumPruzanjaPomoci);
                            this.helpProvided.DatumDolaska = formatDateFromUTC(this.helpProvided.DatumDolaska);
                            this.helpProvided.DatumOdlaska = formatDateFromUTC(this.helpProvided.DatumOdlaska);
                            //this.helpProvided.SlucajID = this.selectedZrtva.value;

                            this.$router.push({ name: 'HelpProvidedEdit', params: { helpProvidedID: this.id } });
                        })
                        .catch(error => {
                            this.errorMessage = "Greska prilikom kreiranja";
                            console.error('Error creating Person Trust:', error);
                        });

                } catch (error) {
                    this.errorMessage = 'Greska prilikom spasavanja!';
                    // Handle error
                } finally {
                    this.isCreating = false;
                }

            }
        },

        resetForm() {
            this.helpProvided = {
                Ime: '',
                Prezime: '',
                DatumPruzanjaPomoci: null,
                TipPomoci: null,
                FazaPostupka: null,
                Komentar: null,
            };
        },

        createhelpProvided(submithelpProvided) {
            const token = localStorage.getItem('jwt');
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            return new Promise((resolve, reject) => {
                axios
                    .post(apiUrl, submithelpProvided, { headers })
                    .then(response => {
                        // Handle successful creation
                        const id = response.data.ID;
                        resolve(id);
                    })
                    .catch(error => {
                        // Handle error during creation
                        this.errorMessage = "Greska prilikom spasavanja"

                        console.error('Error creating Person Trust:', error);
                        reject(error);
                    });
            });
        },

        deleteService() {
            const token = localStorage.getItem('jwt');
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${apiUrl}?id=` + this.id, { headers })
                    .then(response => {
                        // Handle successful creation
                        console.log('Delted id ' + this.id + response.data);
                        this.$router.push({ name: 'HelpProvidedList' });
                    })
                    .catch(error => {
                        // Handle error during creation
                        this.errorMessage = "Greska prilikom brisanja"

                        console.error('Error creating Person Trust:', error);
                        reject(error);
                    });
            });
        },

        updatehelpProvided(submithelpProvided) {
            const token = localStorage.getItem('jwt');
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            axios
                .put(`${apiUrl}`, submithelpProvided, { headers })
                .then(response => {
                    // Handle successful update
                    console.log('Person Trust updated:', response.data);
                    this.successMessage = "Ažurirani podaci"
                    // Perform any necessary actions after successful update (e.g., show success message, redirect)
                })
                .catch(error => {
                    // Handle error during update
                    console.error('Error updating Person Trust:', error);
                    this.errorMessage = "Greska prilikom spasavanja"

                    // Perform any necessary error handling (e.g., show error message)
                });
        },
        closeNotification() {
            this.successMessage = null;
        },
        closeNotificationError() {
            this.errorMessage = null;
        },
    },

    components: {
        Multiselect,
        DeleteButton,

    },
};

function formatDateToUTC(date) {
    // Create a moment object from the input date
    const momentDate = moment(date);

    // Convert the date to UTC
    const utcDate = momentDate.utc(true);

    // Format the date as "YYYY-MM-DDTHH:mm:ssZ"
    const formattedDate = utcDate.format('YYYY-MM-DDTHH:mm:ssZ');
    // const formattedDate = moment(dateString).format('YYYY-MM-DD');


    // Return the formatted date
    return formattedDate;
}

function formatDateFromUTC(date) {
    // Create a moment object from the input date

    const momentDate = moment.utc(date);

    // Convert the date to the user's local timezone
    const localDate = momentDate.local(true);

    // Format the date as "YYYY-MM-DD"
    const formattedDate = localDate.format('YYYY-MM-DD');

    // Return the formatted date
    return formattedDate;
}

</script>
<style>
.multiselect-tag {
    white-space: pre-line;
}

.modal-dialog.modal-dialog-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 1rem);
}
</style>
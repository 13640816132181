<template>
  <div class="position-relative text-start">
    <h1>Pregled korisnika</h1>
    <div class="border-top border-secondary my-4"></div>

    <ul class="nav nav-tabs border-bottom">
      <li class="nav-item border-bottom">
        <a class="nav-link active" data-bs-toggle="tab" href="#tab1">Podaci o korisniku</a>
      </li>
    </ul>

    <div class="tab-content">
      <div class="tab-pane fade show active" id="tab1">
        <br>
        <div class="row">
          <div class="col-md-3">
            <div class="mb-3">
              <label for="name" class="form-label">Ime</label>
              <input type="text" id="name" class="form-control" v-model="user.name" required>
            </div>
          </div>
          <div class="col-md-3">
            <div class="mb-3">
              <label for="lastname" class="form-label">Prezime</label>
              <input type="text" id="lastname" class="form-control" v-model="user.lastname" required>
            </div>
          </div>
          <div class="col-md-3">
            <div class="mb-3">
              <label for="username" class="form-label">Korisničko ime</label>
              <input type="text" id="username" class="form-control" v-model="user.username" readonly>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="mb-3">
              <label for="password" class="form-label">Lozinka</label>
              <input type="password" id="password" class="form-control" v-model="user.password" required>
            </div>
          </div>

          <div class="col-md-3">
            <div class="mb-3">
              <label for="confirmPassword" class="form-label">Potvrdi lozinku</label>
              <input type="password" id="confirmPassword" class="form-control" v-model="confirmPassword" required>
              <div v-if="passwordsMismatch" class="text-danger">Lozinke se ne podudaraju.</div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-md-1">

        <br>
        <div>
          <button type="submit" class="btn btn-success mt-1" @click="handleSubmit"
            :disabled="isSaveDisabled">Spasi</button>
        </div>
      </div>
      <div class="col-md-1" v-if="!CurrentUser">
        <br>
        <DeleteButton @delete="deleteService"></DeleteButton>
        <br>
      </div>
      <div class="d-flex align-items-center">
        <div v-if="errorMessage" class="alert alert-danger alert-dismissible mt-3" style="float: right;">
          {{ errorMessage }}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div v-if="successMessage" class="alert alert-success alert-dismissible mt-3"
          style="max-width: 50%; float: right;">
          {{ successMessage }}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import config from '../config';
import DeleteButton from '@/components/DeleteButton.vue';

const apiUrl = `${config.backendUrl}/users`;

export default {
  data() {
    return {
      user: {
        username: '',
        password: '',
        placeId: 0,
        state: '',
        name: '',
        lastname: '',
        id: null,
      },
      currentUserName: localStorage.getItem('username'),
      CurrentUser: false,
      successMessage: '',

      confirmPassword: '',
    };
  },

  mounted() {
    this.user.username = this.$route.params.username;
    if (this.user.username) {
      this.fetchUser();
    }
    this.isCurrentUserEdited();
  },
  methods: {

    isCurrentUserEdited() {
      this.CurrentUser = this.currentUserName == this.user.username
    },

    deleteService() {
      const token = localStorage.getItem('jwt');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      return new Promise((resolve, reject) => {
        axios
          .delete(`${apiUrl}?id=` + this.user.id, { headers })
          .then(response => {
            // Handle successful creation
            console.log('Delted id ' + this.user.id + response.data);
            this.$router.push({ name: 'UserList' });
          })
          .catch(error => {
            // Handle error during creation
            this.errorMessage = "Greska prilikom brisanja"

            console.error('Error creating Person Trust:', error);
            reject(error);
          });
      });
    },

    isPasswordStrong() {
      // Implement your own password strength check logic here
      const password = this.user.password;
      return password.length >= 8; // Example: Password length of at least 8 characters
    },
    fetchUser() {
      const token = localStorage.getItem('jwt');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      console.log('fetchuser called');
      if (this.user.username) {
        axios.get(`${apiUrl}?username=${this.user.username}`, { headers })
          .then(response => {
            console.log(response.status);
            this.user = response.data;
            this.user.password = '';
          })
          .catch(error => {
            console.error(error);
          });
      }
    },

    handleSubmit() {
      if (this.passwordsMismatch) {
        console.error('Passwords do not match.');
        return;
      }
      const token = localStorage.getItem('jwt');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      const payload = {
        username: this.user.username,
        password: this.user.password,
        placeId: this.user.placeId,
        state: this.user.state,
        name: this.user.name,
        lastname: this.user.lastname,
        id: this.user.id,

      };

      axios.put(apiUrl, payload, { headers })
        .then(response => {
          // Handle success response
          this.successMessage = "Spasene izmijene";
          this.$emit("submit-success");
          console.log(response.status);
        })
        .catch(error => {
          this.successMessage = "Greska prilikom spasavanja podataka error " + error;
          this.$emit("submit-success");
          console.error(error);
        });
    },
  },
  created() {
    const username = this.$route.params.username;
    if (username) {
      this.fetchUser();
    }
  },
  computed: {
    passwordsMismatch() {
      return this.user.password !== this.confirmPassword;
    },
    isSaveDisabled() {
      return (
        !this.user.password ||
        !this.isPasswordStrong() ||
        this.user.password !== this.confirmPassword
      );
    },
  },

  components: {
    DeleteButton,
  }
};
</script>

  
<template>
    <div id="filter" class="row text-start" style="padding-bottom: 1%;">
        <h2 class="text-start">Izvještaj uzroka nasilja nad korisnicima</h2>
        <div class="col-md-2">
            <br>
            <label for="DatumKreiranja" class="form-label">Datum od</label>
            <input type="date" class="form-control" id="DatumKreiranja" v-model="this.DatumKreiranja" required>
        </div>

        <div class="col-md-2">
            <br>
            <label for="DatumKreiranjaDo" class="form-label">Datum do</label>
            <input type="date" class="form-control" id="DatumKreiranjaDo" v-model="this.DatumKreiranjaDo" required>
        </div>
        <div class="col-md-1" style="padding-top: 2%;">
            <br>
            <button @click="fetchData" type="button" class="btn btn-success">Filtriraj</button>
        </div>
        <div class="col-md-1" style="padding-top: 2%;">
            <br>
            <button @click="printData" type="button" class="btn btn-secondary" id="print"><i class="fa-solid fa-print"></i>
                Print</button>
        </div>
    </div>
    <br>
    <br>

    <div>
        <hr id="divider" class="hr">
        <table id="tablePrint" class="table table-striped">
            <thead>
                <tr>
                    <th>Tip</th>
                    <th>Uzrok nasilja</th>
                    <th>Ukupno</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in filteredReportData" :key="item.id">
                    <td>{{ item.Tip }}</td>

                    <td>{{ item.Name }}</td>
                    <td>{{ item.Ukupno }}</td>
                </tr>
            </tbody>
        </table>



    </div>
</template>

<script>

import axios from 'axios';
import config from '../../config';

const apiUrl = `${config.backendUrl}/slucaj/reports`;

const apiUrlLookupList = `${config.backendUrl}/lookup/list`;


export default {
    data() {
        return {
            reportData: [],
            tipFilter: '',
            DatumKreiranja: null,
            DatumKreiranjaDo: null,
            lookupData: [],
            filteredData: [],
        };
    },
    mounted() {
        this.fetchData();
        const token = localStorage.getItem('jwt');
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        axios.get(apiUrlLookupList, { headers })
            .then(response => {
                this.lookupData = response.data.map(item => ({ label: item.value, value: item.id }));

            }).catch(error => {
                console.error('Error:', error);
            });
    },
    methods: {
        getLabelById(id) {
            const lookupItem = this.lookupData.find(item => item.value === id);
            return lookupItem ? lookupItem.label : '-';
        },
        printData() {
            window.print();
        },
        formatDate(date) {
            if (date) {
                let parsedDate = new Date(date);
                if (!isNaN(parsedDate)) {
                    return parsedDate.toISOString().slice(0, 10);
                }
            }
            return ''; // or return a placeholder string like 'N/A' or 'Unknown'
        },

        fetchData() {
            let url = apiUrl + '?report=UzrokNasilja';
            if (this.DatumKreiranja && this.DatumKreiranjaDo) {
                url += `&startDate=${this.DatumKreiranja}&endDate=${this.DatumKreiranjaDo}`;
            }
            const token = localStorage.getItem('jwt');
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            axios.get(url, { headers: headers })
                .then(response => {
                    this.reportData = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
    },

    computed: {
        filteredReportData() {
            return this.reportData.filter(item => {
                const tipMatch = !this.selectedTip || item.Tip === this.selectedTip;

                return tipMatch;
            });
        },

        total() {
            return this.filteredReportData.reduce((total, item) => total + item.Ukupno, 0);
        },
    },

};

</script>

<style>
@media print {
    #menuBar {
        display: none;
    }

    #divider {
        display: none;
    }

    @page {
        margin-bottom: 2.3cm;
        /* adjust as needed */
    }

    #tablePrint {
        bottom: 10px;
    }

    #printButton {
        left: -9999px;
        width: 1px;
        height: 1px;
        visibility: hidden;
    }



    #header {
        display: none;

    }

    #filter {
        display: none;

    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    table {
        width: 100%;
        /* Adjust as needed */
    }

    th,
    td {
        overflow: hidden;
        padding-left: 10px;
    }
}

.custom-print-button {
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    /* Add other custom styles as needed */
}

body {
    margin: 0;
    padding: 0;
}
</style>
<template>
      <meta name="robots" content="noindex">
    <div class="container" style="width: 100%; padding-left: 0%;">

        <div class="wrapper">
            <div class="logo">
                <img src="./logo.svg" alt="">
            </div>
            <div class="text-center mt-4 name">
                Fondacija lokalne demokratije
            </div>
            <form @submit.prevent="login" class="p-3 mt-3">
                <div class="form-field d-flex align-items-center">
                    <span class="far fa-user"></span>
                    <input type="text" name="userName" id="userName" v-model="username" placeholder="Korisničko ime">
                </div>
                <div class="form-field d-flex align-items-center">
                    <span class="fas fa-key"></span>
                    <input type="password" name="password" id="pwd" v-model="password" placeholder="Lozinka">
                </div>
                <button type="submit" class="btn mt-3">Prijavi se</button>
            </form>
            <div v-if="errorMessage" class="alert alert-danger alert-dismissible mt-3 text-start">
                {{ errorMessage }}
                <button type="button" class="btn-close" aria-label="Close" @click="closeNotification"></button>
            </div>
        </div>
    </div>
</template>
  
<script>

import axios from 'axios'
import config from '../config'

export default {
    data() {
        return {
            username: '',
            password: '',
            errorMessage: '',
        }
    },
    methods: {
        closeNotification() {
            this.errorMessage = null;
        },
        async login() {
            try {

                const response = await axios.post(`${config.backendUrl}/login`, {
                    username: this.username,
                    password: this.password,
                });
                const token = response.data.token;
                if (token) {
                    localStorage.setItem('jwt', token); // Store the JWT in the localStorage
                    localStorage.setItem('username', this.username);

                    //   localStorage.setItem('jwt', token);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;


                    this.$router.push('/slucaj-list'); // Redirect to the protected page
                } else {
                    console.log('Error during login:1');
                    this.errorMessage = "Greska prilikom prijave";
                }
            } catch (error) {
                console.error('Error during login:', error);

                if (error.response && error.response.status === 404) {
                    this.errorMessage = "Greska prilikom prijave";

                } else {
                    this.errorMessage = "Greska prilikom prijave";

                    this.error = 'Login failed. Please check your credentials.';
                }
            }
        },
    }
}
</script>

<style>
/* Importing fonts from Google */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

/* Reseting */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    background: #ecf0f3;
}

.wrapper {
    max-width: 350px;
    min-height: 500px;
    margin: 80px auto;
    padding: 40px 30px 30px 30px;
    background-color: #ecf0f3;
    border-radius: 15px;
    box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff;
}

.logo {
    width: 80px;
    margin: auto;
}

.logo img {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 3px #5f5f5f,
        0px 0px 0px 5px #ecf0f3,
        8px 8px 15px #a7aaa7,
        -8px -8px 15px #fff;
}

.wrapper .name {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.3px;
    padding-left: 10px;
    color: #555;
}

.wrapper .form-field input {
    width: 100%;
    display: block;
    border: none;
    outline: none;
    background: none;
    font-size: 1.2rem;
    color: #666;
    padding: 10px 15px 10px 10px;
    /* border: 1px solid red; */
}

.wrapper .form-field {
    padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;
}

.wrapper .form-field .fas {
    color: #555;
}

.wrapper .btn {
    box-shadow: none;
    width: 100%;
    height: 40px;
    background-color: #03A9F4;
    color: #fff;
    border-radius: 25px;
    box-shadow: 3px 3px 3px #b1b1b1,
        -3px -3px 3px #fff;
    letter-spacing: 1.3px;
}

.wrapper .btn:hover {
    background-color: #039BE5;
}

.wrapper a {
    text-decoration: none;
    font-size: 0.8rem;
    color: #03A9F4;
}

.wrapper a:hover {
    color: #039BE5;
}

@media(max-width: 380px) {
    .wrapper {
        margin: 30px 20px;
        padding: 40px 15px 15px 15px;
    }
}
</style>
  
<template>
  <div>
    <h2>Pregled žrtava nasilja</h2>

    <div class="mb-3">
      <input type="text" class="form-control" placeholder="Pretraži..." v-model="searchQuery" />
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th>Uredi</th>
          <th>Tip korisnika</th>
          <th>Ime</th>
          <th>Prezime</th>
          <th>JMBG</th>
          <th>Adresa</th>
          <th>Datum Rođenja</th>
          <!-- <th>Datum dolaska</th> -->

          <th>Datum unosa</th>

        </tr>
      </thead>
      <tbody>
        <tr v-for="slucaj in filteredSlucajList" :key="slucaj.ID" @click="goToCaseDetails(slucaj)">
          <td style="width: 1%"><i class="fa-solid fa-user-pen"></i></td>
          <td style="width: 10%">{{ slucaj.Tip }}</td>

          <td style="width: 10%">{{ slucaj.Ime }}</td>
          <td style="width: 10%">{{ slucaj.Prezime }}</td>
          <td style="width: 10%">{{ slucaj.JMBG }}</td>
          <td style="width: 10%">{{ slucaj.Adresa }}</td>
          <td style="width: 10%">{{ formatDate(slucaj.DatumRodjenja) }}</td>
         <!-- <td style="width: 10%">{{ formatDate(slucaj.DatumUlaska) }}</td> -->
          <td style="width: 10%">{{ formatDate(slucaj.CreatedAt) }}</td>

        </tr>
        <tr v-if="filteredSlucajList.length === 0">
          <td colspan="12" class="text-center">Nema podataka.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
  
<script>
import axios from 'axios';
import config from '../config';

const apiUrl = `${config.backendUrl}/slucaj`;

export default {
  name: 'SlucajList',
  data() {
    return {
      slucajList: [],
      searchQuery: '',
    };
  },
  async mounted() {
    try {
      const token = localStorage.getItem('jwt');
      const response = await axios.get(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      this.slucajList = response.data;
    } catch (error) {
      console.error('Error fetching slucaj list:', error);
    }
  },
  computed: {
    filteredSlucajList() {
      const query = this.searchQuery.toLowerCase().trim();
      if (!query) {
        return this.slucajList;
      }

      const searchTerms = query.split(' ');

      return this.slucajList.filter(slucaj => {
        const firstName = slucaj.Ime ? slucaj.Ime.toLowerCase() : '';
        const lastName = slucaj.Prezime ? slucaj.Prezime.toLowerCase() : '';
        // Check if each search term is present in any searchable field
        return searchTerms.every(term =>
          firstName.includes(term) ||
          lastName.includes(term) ||
          (slucaj.Adresa && slucaj.Adresa.toLowerCase().includes(term)) ||
          (slucaj.CreatedAt && slucaj.CreatedAt.includes(term)) ||
          (slucaj.DatumRodjenja && slucaj.DatumRodjenja.includes(term)) ||
          // (slucaj.DatumUlaska && slucaj.DatumUlaska.includes(term)) ||
          (slucaj.JMBG && slucaj.JMBG.includes(term))
        );
      });
    },

  },
  methods: {
    goToCaseDetails(slucaj) {
      this.$router.push({ name: 'SlucajEdit', params: { slucajId: slucaj.ID, editMode: true } });
    },
    formatDate(date) {
      if (date) {
        let parsedDate = new Date(date);
        if (!isNaN(parsedDate)) {
          return parsedDate.toISOString().slice(0, 10);
        }
      }
      return ''; // or return a placeholder string like 'N/A' or 'Unknown'
    },
  },
};
</script>
  
<style>
tbody tr:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.even-row {
  background-color: #f2f2f2;
  /* Replace with your desired color */
}
</style>
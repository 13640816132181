<template>
    <div>
        <div class="hidden-on-screen">
            <div class="container-fluid">
                <div class="row justify-content-between">
                    <div class="col-lg-8">
                        <div class="form-group">
                            <div class="col-auto mx-auto">
                                <img src="./IspisLogo.png" alt="Image" style="max-width: 300px; margin-left: 500px;">
                            </div>
                            <div class="col-auto mx-auto">
                                <label for="name" class="form-label"
                                    style="font-weight: bold; margin-bottom: 30px; margin-left: 250px; text-align: center;">SKLONIŠTE
                                    ZA ŽENE I DJECU ŽRTVE
                                    NASILJA U PORODICI</label>
                            </div>
                            <br> <br> <br> <br>

                            <label for="title" class="form-label"
                                style="font-weight: bold; margin-bottom: 100px;  margin-left: 250px; text-align: center;">UPITNIK
                                ZA DJECU</label>
                            <br> <br>
                            <div class="row">
                                <div class="col-md-2" style="width: 160px; ">
                                    <label for="name" class="form-label"
                                        style="font-weight: bold; text-align: left; margin-bottom: 5px;">Ime i
                                        prezime:</label>
                                </div>
                                <div class="col-md-3" style=" text-align: left;">
                                    <label for="name" class="form-label"
                                        style="font-weight: text-align: left; margin-bottom: 5px;">{{ child.Ime + " "
                                            + child.Prezime }}</label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-3" style="width: 160px; ">

                                    <label for="dateAdmission" class="form-label"
                                        style="font-weight: bold; text-align: left; margin-bottom: 5px;">Datum
                                        prijema:</label>
                                </div>
                                <div class="col-md-3" style=" text-align: left;">
                                    <label for="dateAdmission" class="form-label"
                                        style="font-weight: text-align: left; margin-bottom: 5px;">
                                        {{ formatDate(child.DatumUlaska) }}</label>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-2" style="width: 160px; ">

                                    <label for="dateDischarge" class="form-label"
                                        style="font-weight: bold; text-align: left; margin-bottom: 5px;">Datum
                                        otpusta:</label>
                                </div>
                                <div class="col-md-2" style=" text-align: left;">
                                    <label for="dateDischarge" class="form-label"
                                        style="font-weight: text-align: left; margin-bottom: 5px;">
                                        {{ formatDate(child.DatumOdlaska) }}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="container  rounded-3">
            <div class="container text-start">
                <h2>Podaci o dijetetu</h2>
                <br>
                <form @submit.prevent="saveChild">

                    <div class="container custom-dashboard"
                        style="border:1px solid #cecece; width: 100%;border-radius: 25px">
                        <br>

                        <div class="row">
                            <!-- Form fields for child data -->
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label for="ime" class="form-label">Ime</label>
                                    <input type="text" id="ime" class="form-control" v-model="child.Ime" required>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">

                                    <label for="prezime" class="form-label">Prezime</label>
                                    <input type="text" id="prezime" class="form-control" v-model="child.Prezime" required>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <!-- Adresa -->
                                <div class="mb-3">
                                    <label for="adresa" class="form-label">Adresa</label>
                                    <input type="text" class="form-control" id="adresa" v-model="child.Adresa">
                                </div>
                            </div>

                            <div class="col-md-3">
                                <label for="datumRodenja" class="form-label">Datum Rođenja</label>
                                <input type="date" class="form-control" id="datumRodenja" v-model="child.DatumRodenja">
                            </div>

                        </div>


                        <div class="row">

                            <!-- MjestoRodjenja -->
                            <div class="col-md-3">
                                <label class="form-label" for="mjestoRodjenja">Mjesto Rođenja</label>
                                <input type="text" class="form-control" id="mjestoRodjenja" v-model="child.MjestoRodjenja">
                            </div>

                            <!-- JMBG -->
                            <div class="col-md-3">
                                <label class="form-label" for="jmbg">JMBG</label>
                                <input type="text" class="form-control" id="jmbg" v-model="child.JMBG">
                            </div>

                            <!-- DijetePoRedu -->
                            <div class="col-md-3">
                                <label class="form-label" for="dijetePoRedu">Koje vam je ovo dijete po rođenju</label>
                                <input type="number" class="form-control" id="dijetePoRedu" v-model="child.DijetePoRedu">
                            </div>

                            <!-- DobDjetata -->
                            <div class="col-md-3">
                                <label class="form-label" for="dobDjetata">Dob Djetata</label>
                                <input type="text" class="form-control" id="dobDjetata" v-model="child.DobDjetata">
                            </div>
                        </div>
                        <br>


                        <div class="row">

                            <!-- DijetePrhodalo sa koliko mjeseci -->
                            <div class="col-md-3">
                                <br>
                                <label class="form-label" for="dijetePrhodalo">Sa koliko mjeseci je Vaše dijete
                                    prohodalo?</label>
                                <input type="number" class="form-control" id="dijetePrhodalo"
                                    v-model="child.DijetePrhodalo">
                            </div>

                            <!-- DjeteProgovrilo -->
                            <div class="col-md-3">
                                <br>
                                <label class="form-label" for="djeteProgovrilo">Sa koliko mjeseci je Vaše dijete
                                    progovorilo?</label>
                                <input type="number" class="form-control" id="djeteProgovrilo"
                                    v-model="child.DjeteProgovrilo">
                            </div>

                            <!-- DijeteProblemi -->


                            <div class="col-md-3">
                                <br>
                                <br>
                                <label class="form-label" for="dijeteVrstaNasilja">Dijete ima problema sa</label>
                                <Multiselect v-model="child.DijeteProblemiArray" mode="tags" :close-on-select="false"
                                    :searchable="true" :create-option="false" :options=DijeteProblemiList />
                            </div>

                            <!-- DijeteUsloviZaZivot -->
                            <div class="col-md-3">

                                <label class="form-label" for="dijeteUsloviZaZivot">Smatrate li da ste Vašem djetetu mogli
                                    pružiti adekvatne uslove za život</label>
                                <Multiselect v-model="child.DijeteUsloviZaZivotArray" mode="tags" :close-on-select="false"
                                    :searchable="true" :create-option="false" :options=DijeteUsloviZaZivotList />
                            </div>
                        </div>

                        <div class="row">


                            <!-- DijeteCestoBolesno -->
                            <div class="col-md-3">
                                <br>
                                <br>
                                <label for="dijeteCestoBolesno" class="form-label">Da li je vaše dijete često
                                    bolesno</label>
                                <select class="form-control" id="dijeteCestoBolesno" v-model="selecteddijeteCestoBolesno">
                                    <option disabled value="">Odaberite</option>
                                    <option v-for="status in DaNeList" :key="status.value" :value="status.value">{{
                                        status.label }}</option>
                                </select>
                            </div>

                            <!-- DijeteSklonoPovredjivanju -->
                            <div class="col-md-3">
                                <br>
                                <label class="form-label" for="dijeteSklonoPovredjivanju">Da li je Vaše dijete bilo sklono
                                    pvređivanju</label>
                                <select class="form-control" id="dijeteSklonoPovredjivanju"
                                    v-model="selecteddijeteSklonoPovredjivanju">
                                    <option disabled value="">Odaberite</option>
                                    <option v-for="status in DaNeList" :key="status.value" :value="status.value">{{
                                        status.label }}</option>
                                </select>
                            </div>

                            <!-- DobDjetetaPrveNesuglasice -->
                            <div class="col-md-3">
                                <br>
                                <label class="form-label" for="dobDjetetaPrveNesuglasice">Koliko je Vaše dijete bilo staro
                                    kad su počele vaše nesuglasice u porodici?</label>
                                <input type="text" class="form-control" id="dobDjetetaPrveNesuglasice"
                                    v-model="child.DobDjetetaPrveNesuglasice">
                            </div>

                            <!-- DijetePrisutnoNasilju -->
                            <div class="col-md-3">
                                <br>
                                <label class="form-label" for="dijetePrisutnoNasilju">Da li je dijete prisustvovalo kad Vas
                                    je suprug fizički zlostavljao?</label>
                                <select class="form-control" id="dijetePrisutnoNasilju"
                                    v-model="selecteddijetePrisutnoNasilju">
                                    <option disabled value="">Odaberite</option>
                                    <option v-for="status in DaNeList" :key="status.value" :value="status.value">{{
                                        status.label }}</option>
                                </select>
                            </div>
                        </div>
                        <br>

                    </div>
                    <br>

                    <div class="container custom-dashboard"
                        style="border:1px solid #cecece; width: 100%; border-radius: 25px">
                        <br>
                        <div class="row">

                            <!-- DijeteSkola -->
                            <div class="col-md-3">
                                <br>
                                <label class="form-label" for="dijeteSkola">Da li sada redovno pohađa školu, razred</label>
                                <select class="form-control" id="dijeteSkola" v-model="selecteddijeteSkola">
                                    <option disabled value="">Odaberite</option>
                                    <option v-for="status in DaNeList" :key="status.value" :value="status.value">{{
                                        status.label }}</option>
                                </select>
                            </div>

                            <div class="col-md-3">
                                <br>
                                <br>
                                <label for="Razred" class="form-label">Razred u školi</label>
                                <input type="number" class="form-control" id="Razred" v-model="child.Razred">

                            </div>
                            <!-- DijeteZavrseniRazredUspijeg -->
                            <div class="col-md-3">
                                <br>

                                <label class="form-label" for="dijeteZavrseniRazredUspijeg">Koji uspjehom ste zavšili
                                    prethodni stepen školovanja?</label>
                                <select class="form-control" id="dijeteZavrseniRazredUspijeg"
                                    v-model="selecteddijeteZavrseniRazredUspije">
                                    <option disabled value="">Odaberite</option>
                                    <option v-for="status in UspijehList" :key="status.value" :value="status.value">{{
                                        status.label }}</option>
                                </select>
                            </div>

                            <!-- DijeteNapustaloSkolu -->
                            <div class="col-md-3">
                                <br>

                                <label class="form-label" for="dijeteNapustaloSkolu">Da li ste napuštali redovno
                                    školovanje</label>
                                <select class="form-control" id="dijeteNapustaloSkolu"
                                    v-model="selecteddijeteNapustaloSkolu">
                                    <option disabled value="">Odaberite</option>
                                    <option v-for="status in DaNeList" :key="status.value" :value="status.value">{{
                                        status.label }}</option>
                                </select>
                            </div>


                        </div>
                        <div class="row">
                            <!-- DijeteZeliliNastavitiPrekinutoskolovanje -->
                            <div class="col-md-3">
                                <br>

                                <label class="form-label" for="dijeteZeliliNastavitiPrekinutoskolovanje">Da li želite da
                                    nastavite prekinuto školovanje?</label>
                                <select class="form-control" id="dijeteZeliliNastavitiPrekinutoskolovanje"
                                    v-model="selecteddijeteZeliliNastavitiPrekinutoskolovanje">
                                    <option disabled value="">Odaberite</option>
                                    <option v-for="status in DaNeList" :key="status.value" :value="status.value">{{
                                        status.label }}</option>
                                </select>
                            </div>
                            <!-- DijeteNaupostaloSkoluKomentar -->
                            <div class="col-md-3">
                                <br>
                                <label class="form-label" for="dijeteNaupostaloSkoluKomentar">Dijete napuštalo školovanje
                                    komentar</label>
                                <textarea class="form-control" id="dijeteNaupostaloSkoluKomentar"
                                    v-model="child.DijeteNaupostaloSkoluKomentar" rows="5"></textarea>
                            </div>

                            <!-- DijetePrveSvadjeRoditelja -->
                            <div class="col-md-3">
                                <br>

                                <label class="form-label" for="dijetePrveSvadjeRoditelja">Možete li se sjetiti kad su počele
                                    svađe Vaših roditelja?</label>
                                <textarea class="form-control" id="dijetePrveSvadjeRoditelja" rows="5"
                                    v-model="child.DijetePrveSvadjeRoditelja"></textarea>
                            </div>
                        </div>
                        <!-- DijeteZrtvaNasilje -->
                        <div class="hidden-on-screen">
                            <br> <br>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <br>
                                <br>

                                <label class="form-label" for="dijeteZrtvaNasilje">Da li ste doživjeli nasilje u
                                    porodici</label>
                                <select class="form-control" id="dijeteZrtvaNasilje" v-model="selecteddijeteZrtvaNasilje">
                                    <option disabled value="">Odaberite</option>
                                    <option v-for="status in DaNeList" :key="status.value" :value="status.value">{{
                                        status.label }}</option>
                                </select>
                                <div class="row">
                                    <!-- DijetePosliejdeiceNasilja -->

                                    <div class="col-md-12">
                                        <label class="form-label" for="dijetePosliejdeiceNasilja">Imate li posljedice
                                            nasilja</label>
                                        <select class="form-control" id="dijetePosliejdeiceNasilja"
                                            v-model="selecteddijetePosliejdeiceNasilja">
                                            <option disabled value="">Odaberite</option>
                                            <option v-for="status in DaNeList" :key="status.value" :value="status.value">{{
                                                status.label }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <!-- DijeteVrstaNasilja -->
                            <div class="col-md-3">
                                <br>
                                <br>
                                <label class="form-label" for="dijeteVrstaNasilja">Ako je odgovor da koje vrstu
                                    nasilja:</label>
                                <Multiselect v-model="child.DijeteVrstaNasiljaArray" mode="tags" :close-on-select="false"
                                    :searchable="true" :create-option="false" :options=DijeteVrstaNasiljaList />
                                <div class="row">
                                    <br><br>
                                    <!-- DijetePosliejdeiceNasiljaVrsta -->
                                    <div class="col-md-12">
                                        <label class="form-label" for="DijetePosliejdeiceNasiljaVrsta">Posljedice
                                            nasilja</label>
                                        <Multiselect v-model="child.DijetePosliejdeiceNasiljaVrstaArray" mode="tags"
                                            :close-on-select="false" :searchable="true" :create-option="false"
                                            :options=dijetePosliejdeiceNasiljaList />
                                    </div>
                                </div>
                            </div>
                            <!-- MjereZastite -->
                            <div class="col-md-3">
                                <br><br>
                                <label class="form-label" for="mjereZastite">Da li su izrečene zaštitne mjere</label>
                                <select class="form-control" id="mjereZastite" v-model="selectedmjereZastite">
                                    <option disabled value="">Odaberite</option>
                                    <option v-for="status in DaNeList" :key="status.value" :value="status.value">{{
                                        status.label }}</option>
                                </select>

                                <!-- TrajanjeMjere -->
                                <div class="col-md-12">
                                    <label class="form-label" for="trajanjeMjere">Trajanje mjere</label>
                                    <input type="text" class="form-control" id="trajanjeMjere"
                                        v-model="child.TrajanjeMjere">
                                </div>
                            </div>

                            <!-- VrsteMjereZastite -->
                            <div class="col-md-3">
                                <br><br>
                                <label class="form-label" for="VrsteMjereZastite">Vrste mjere zaštite</label>

                                <Multiselect v-model="child.VrsteMjereZastiteArray" mode="tags" :close-on-select="false"
                                    :searchable="true" :create-option="false" :options=vrstaMjereList />
                            </div>


                        </div>



                        <div class="row">
                            <!-- Komentar -->
                            <div class="col-md-6">
                                <label class="form-label" for="komentar">Komentar</label>
                                <textarea class="form-control" id="komentar" v-model="child.Komentar" rows="3"></textarea>
                            </div>

                            <!-- OpisMajke -->
                            <div class="col-md-6">
                                <label class="form-label" for="opisMajke">Opis Majke</label>
                                <textarea class="form-control" id="opisMajke" v-model="child.OpisMajke" rows="3"></textarea>
                            </div>

                            <!-- OpisOca -->
                            <div class="col-md-6">
                                <label class="form-label" for="opisOca">Opis Oca</label>
                                <textarea class="form-control" id="opisOca" v-model="child.OpisOca" rows="3"></textarea>
                            </div>

                            <!-- OpisBrataSestre -->
                            <div class="col-md-6">
                                <label class="form-label" for="opisBrataSestre">Opis Brata/Sestre</label>
                                <textarea class="form-control" id="opisBrataSestre" v-model="child.OpisBrataSestre"
                                    rows="3"></textarea>
                            </div>

                        </div>
                        <div class="row">

                            <!-- NapustanjeSklonista -->
                            <div class="col-md-6">
                                <label class="form-label" for="napustanjeSklonista">Napuštanje Skloništa</label>
                                <textarea class="form-control" id="napustanjeSklonista" v-model="child.NapustanjeSklonista"
                                    rows="3"></textarea>
                            </div>

                        </div>
                        <br>

                    </div>
                </form>

                <div class="row">
                    <div class="row align-items-end">

                        <div class="col-md-1">
                            <br>
                            <br>
                            <button v-if="!isEditMode" type="submit" class="btn btn-success" @click="saveChild"
                                :disabled="isCreating">Kreiraj</button>
                            <button v-if="isEditMode" type="submit" class="btn btn-success"
                                @click="saveChild">Spasi</button>
                        </div>
                        <div class="col-md-1" v-if="isEditMode">
                            <br>
                            <br>
                            <DeleteButton @delete="deleteService"></DeleteButton>
                        </div>

                        <div class="col-md-1" v-if="isEditMode">
                            <br>
                            <br>

                            <button @click="printPage" class="btn btn-secondary">Print</button>
                        </div>

                        <div class="col-md-2">
                            <div v-if="successMessage" class="alert alert-success alert-dismissible mt-3"
                                style="max-width: 20%; padding-right: 100%;">
                                {{ successMessage }}
                                <button type="button" class="btn-close" aria-label="Close"
                                    @click="closeNotification"></button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
</template>
  
<script>
import axios from 'axios';
import config from '../config';
import moment from 'moment';
import Multiselect from '@vueform/multiselect'
import DeleteButton from '@/components/DeleteButton.vue';


const apiUrlLookupList = `${config.backendUrl}/lookup/list`;
const apiUrl = `${config.backendUrl}/child`;


export default {
    data() {
        return {
            child: {
                Ime: '',
                Prezime: '',
                Adresa: '',
                DatumRodenja: null,
                MjestoRodjenja: '',
                JMBG: '',
                DijetePoRedu: null,
                DobDjetata: '',
                DijetePrhodalo: null,
                DjeteProgovrilo: null,
                DijeteProblemi: null,
                DijeteUsloviZaZivot: '',
                dijeteCestoBolesno: null,
                dijeteSklonoPovredjivanju: null,
                dobDjetetaPrveNesuglasice: '',
                dijetePrisutnoNasilju: null,
                dijeteSkola: null,
                dijeteZavrseniRazredUspijeg: null,
                dijeteNapustaloSkolu: null,
                dijeteNaupostaloSkoluKomentar: '',
                dijeteZeliliNastavitiPrekinutoskolovanje: null,
                dijetePrveSvadjeRoditelja: '',
                dijeteZrtvaNasilje: null,
                dijeteVrstaNasilja: '',
                DijetePosliejdeiceNasilja: null,
                DijetePosliejdeiceNasiljaVrsta: '',
                mjereZastite: null,
                VrsteMjereZastite: null,
                trajanjeMjere: '',
                Komentar: '',
                OpisMajke: '',
                OpisOca: '',
                OpisBrataSestre: '',
                NapustanjeSklonista: '',
                DijeteVrstaNasiljaArray: [],
                DijetePosliejdeiceNasiljaVrstaArray: [],
                DijeteProblemiArray: [],
                DijeteUsloviZaZivotArray: [],
                VrsteMjereZastiteArray: [],
                Razred: null,
                ID: null,
                DatumUlaska: null,
                DatumOdlaska: null,

            },
            DaNeList: [{ value: 0, label: 'Ne' }, { value: 1, label: 'Da' }],
            isEditMode: false, // flag to determine whether it's in edit mode
            UspijehList: [],
            DijeteVrstaNasiljaList: [],
            dijetePosliejdeiceNasiljaList: [],
            vrstaMjereList: [],
            successMessage: '',
            errorMessage: '',
            DijeteUsloviZaZivotList: [],
            DijeteProblemiList: [],
            isCreating: false,
        };
    },

    methods: {
        formatDate(date) {
            if (date) {
                const parts = date.split('-');
                return `${parts[2]}-${parts[1]}-${parts[0]}`;
            }
            else return date;
        },
        printPage() {
            window.print();
        },
        arrayToString(array) {
            if (array) {
                return array.join(',');
            }
            return '';
        },
        saveChild() {
            var submitChild = this.child;

            if (!submitChild.DatumRodenja) {
                submitChild.DatumRodenja = null;
            } else {
                var dateUTC = formatDateToUTC(submitChild.DatumRodenja);
                submitChild.DatumRodenja = dateUTC;
            }

            if (!submitChild.DatumUlaska) {
                submitChild.DatumUlaska = null;
            } else {
                dateUTC = formatDateToUTC(submitChild.DatumUlaska);
                submitChild.DatumUlaska = dateUTC;
            }

            if (!submitChild.DatumOdlaska) {
                submitChild.DatumOdlaska = null;
            } else {
                dateUTC = formatDateToUTC(submitChild.DatumOdlaska);
                submitChild.DatumOdlaska = dateUTC;
            }

            submitChild.DijeteVrstaNasilja = this.arrayToString(submitChild.DijeteVrstaNasiljaArray);
            submitChild.DijetePosliejdeiceNasiljaVrsta = this.arrayToString(submitChild.DijetePosliejdeiceNasiljaVrstaArray);
            submitChild.DijeteProblemi = this.arrayToString(submitChild.DijeteProblemiArray);
            submitChild.DijeteUsloviZaZivot = this.arrayToString(submitChild.DijeteUsloviZaZivotArray);
            submitChild.VrsteMjereZastite = this.arrayToString(submitChild.VrsteMjereZastiteArray);

            const token = localStorage.getItem('jwt');
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            if (this.isEditMode) {
                axios
                    .put(`${config.backendUrl}/child`, this.child, { headers })
                    .then(response => {
                        console.log('Child updated:', response.data);
                        this.successMessage = 'Ažurirani podaci!';

                    })
                    .catch(error => {
                        this.errorMessage = 'Greska prilikom spasavanja!';

                        console.error('Error updating child:', error);
                    });
            } else if (!this.isCreating) {
                this.isCreating = true;

                (async () => {
                    try {
                        const response = await axios.post(`${config.backendUrl}/child`, this.child, { headers });
                        this.successMessage = 'Podaci snimljeni!';
                        const id = response.data.ID;
                        const editUrl = `/child/edit/${id}`;
                        this.$router.push(editUrl);
                    } catch (error) {
                        console.error('Error creating child:', error);
                        this.errorMessage = 'Greska prilikom spasavanja!';
                        // Handle error
                    } finally {
                        this.isCreating = false;
                    }
                })();
            }
        },

        deleteService() {
            const token = localStorage.getItem('jwt');
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            return new Promise((resolve, reject) => {
                this.successMessage = '';
                axios
                    .delete(`${apiUrl}?id=` + this.child.ID, { headers })
                    .then(response => {
                        // Handle successful creation
                        console.log('Delted id ' + this.id + response.data);
                        this.$router.push({ name: 'ChildPageList' });
                    })
                    .catch(error => {
                        // Handle error during creation
                        this.errorMessage = "Greska prilikom brisanja"

                        console.error('Error creating Person Trust:', error);
                        reject(error);
                    });
            });
        },

        closeNotification() {
            this.successMessage = null;
        },
    },
    mounted() {

        const childId = this.$route.params.childID;
        if (childId) {
            this.isEditMode = true;
            const token = localStorage.getItem('jwt');
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            axios.get(`${config.backendUrl}/child?id=${childId}`, { headers })
                .then(response => {
                    response.data.DatumRodenja
                    if (response.data.DatumRodenja) {
                        var fD = formatDateFromUTC(response.data.DatumRodenja);
                        response.data.DatumRodenja = fD;
                    }

                    if (response.data.DatumOdlaska) {
                        fD = formatDateFromUTC(response.data.DatumOdlaska);
                        response.data.DatumOdlaska = fD;
                    }

                    if (response.data.DatumUlaska) {
                        fD = formatDateFromUTC(response.data.DatumUlaska);
                        response.data.DatumUlaska = fD;
                    }
                    this.child = response.data;
                    1
                    if (this.child.DijeteVrstaNasilja) {
                        this.child.DijeteVrstaNasiljaArray = this.child.DijeteVrstaNasilja.split(',');
                    }
                    if (this.child.DijetePosliejdeiceNasiljaVrsta) {
                        this.child.DijetePosliejdeiceNasiljaVrstaArray = this.child.DijetePosliejdeiceNasiljaVrsta.split(',');
                    }
                    if (this.child.DijeteUsloviZaZivot) {
                        this.child.DijeteUsloviZaZivotArray = this.child.DijeteUsloviZaZivot.split(',');
                    }
                    if (this.child.DijeteProblemi) {
                        this.child.DijeteProblemiArray = this.child.DijeteProblemi.split(',');
                    }
                    if (this.child.VrsteMjereZastite) {
                        this.child.VrsteMjereZastiteArray = this.child.VrsteMjereZastite.split(',');
                    }

                })
                .catch(error => {
                    console.error('Error fetching child data:', error);
                    // Handle error
                });


        }

        const token = localStorage.getItem('jwt');
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };

        axios.get(apiUrlLookupList, { headers })
            .then(response => {
                const lookupData = response.data;
                this.UspijehList = lookupData.filter(item => item.name === 'uspijeh_u_skoli').map(item => ({ label: item.value, value: item.id }));
                this.DijeteVrstaNasiljaList = lookupData.filter(item => item.name === 'vrsta_nasilja_nad_djetetom').map(item => ({ label: item.value, value: item.id }));
                this.dijetePosliejdeiceNasiljaList = lookupData.filter(item => item.name === 'dijete_poslijedice_nasilja').map(item => ({ label: item.value, value: item.id }));
                this.vrstaMjereList = lookupData.filter(item => item.name === 'vrsta_mjere').map(item => ({ label: item.value, value: item.id }));
                this.DijeteProblemiList = lookupData.filter(item => item.name === 'djete_problemi').map(item => ({ label: item.value, value: item.id }));
                this.DijeteUsloviZaZivotList = lookupData.filter(item => item.name === 'djete_uslovi_za_zivot').map(item => ({ label: item.value, value: item.id }));



            }).catch(error => {
                console.error('Error:', error);
            });
    },

    computed: {

        selectedmjereZastite: {
            get() {
                return this.DaNeList.find(item => item.value === this.child.MjereZastite)?.value;
            },
            set(value) {
                this.child.MjereZastite = value;
            },
        },


        selecteddijeteCestoBolesno: {
            get() {
                return this.DaNeList.find(item => item.value === this.child.DijeteCestoBolesno)?.value;
            },
            set(value) {
                this.child.DijeteCestoBolesno = value;
            },
        },

        selecteddijeteSklonoPovredjivanju: {
            get() {
                return this.DaNeList.find(item => item.value === this.child.DijeteSklonoPovredjivanju)?.value;
            },
            set(value) {
                this.child.DijeteSklonoPovredjivanju = value;
            },
        },

        selecteddijetePrisutnoNasilju: {
            get() {
                return this.DaNeList.find(item => item.value === this.child.DijetePrisutnoNasilju)?.value;
            },
            set(value) {
                this.child.DijetePrisutnoNasilju = value;
            },
        },

        selecteddijeteSkola: {
            get() {
                return this.DaNeList.find(item => item.value === this.child.DijeteSkola)?.value;
            },
            set(value) {
                this.child.DijeteSkola = value;
            },
        },

        selecteddijeteZavrseniRazredUspije: {
            get() {
                return this.UspijehList.find(item => item.value === this.child.DijeteZavrseniRazredUspijeg)?.value;
            },
            set(value) {
                this.child.DijeteZavrseniRazredUspijeg = value;
            },
        },

        selecteddijeteNapustaloSkolu: {
            get() {
                return this.DaNeList.find(item => item.value === this.child.DijeteNapustaloSkolu)?.value;
            },
            set(value) {
                this.child.DijeteNapustaloSkolu = value;
            },
        },

        selecteddijeteZeliliNastavitiPrekinutoskolovanje: {
            get() {
                return this.DaNeList.find(item => item.value === this.child.DijeteZeliliNastavitiPrekinutoskolovanje)?.value;
            },
            set(value) {
                this.child.DijeteZeliliNastavitiPrekinutoskolovanje = value;
            },
        },

        selecteddijeteZrtvaNasilje: {
            get() {
                return this.DaNeList.find(item => item.value === this.child.DijeteZrtvaNasilje)?.value;
            },
            set(value) {
                this.child.DijeteZrtvaNasilje = value;
            },
        },

        selecteddijetePosliejdeiceNasilja: {
            get() {
                return this.DaNeList.find(item => item.value === this.child.DijetePosliejdeiceNasilja)?.value;
            },
            set(value) {
                this.child.DijetePosliejdeiceNasilja = value;
            },
        },

    },

    components: {
        Multiselect,
        DeleteButton,
    },
};


function formatDateToUTC(date) {
    // Create a moment object from the input date
    const momentDate = moment(date);

    // Convert the date to UTC
    const utcDate = momentDate.utc(true);

    // Format the date as "YYYY-MM-DDTHH:mm:ssZ"
    const formattedDate = utcDate.format('YYYY-MM-DDTHH:mm:ssZ');
    // const formattedDate = moment(dateString).format('YYYY-MM-DD');

    // Return the formatted date
    return formattedDate;
}

function formatDateFromUTC(date) {
    // Create a moment object from the input date

    const momentDate = moment.utc(date);

    // Convert the date to the user's local timezone
    const localDate = momentDate.local(true);

    // Format the date as "YYYY-MM-DD"
    const formattedDate = localDate.format('YYYY-MM-DD');
    // Return the formatted date
    return formattedDate;
}
</script>
<style>
@import url('@vueform/multiselect/themes/default.css');

.custom-bg-div {
    background-color: #ffffff;
    border-top: 0px solid #0091b5;
    border-left-color: #fff;
    border-right-color: #fff;
    color: #000000;
    border-radius: 25px;
}

.hidden-on-screen {
    display: none;
}

/* Styles for printing */
@media print {
    .hidden-on-screen {
        display: block;
        /* or any other appropriate display value for printing */
    }

    .btn.btn-success.mt-1,
    .btn-close,
    .btn.btn-danger.btn-sm,
    .btn.btn-success,
    .btn.btn-secondary,

    .btn.btn-danger {
        display: none;
    }
}
</style>
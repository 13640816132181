<template>
  <div class="position-relative text-start">
    <h1>Dodavanje korisnika </h1>
    <div class="border-top border-secondary my-4"></div>

    <ul class="nav nav-tabs border-bottom">
      <li class="nav-item border-bottom">
        <a class="nav-link active" data-bs-toggle="tab" href="#tab1">Podaci o korisniku</a>
      </li>

    </ul>
    <div class="tab-content">
      <div class="tab-pane fade show active" id="tab1">
        <br>
        <div class="row">

          <div class="col-md-3">
            <div class="mb-3">
              <label for="name" class="form-label">Ime</label>
              <input type="text" id="name" class="form-control" v-model="user.name" required>
            </div>
          </div>
          <div class="col-md-3">
            <div class="mb-3">
              <label for="lastname" class="form-label">Prezime</label>
              <input type="text" id="lastname" class="form-control" v-model="user.lastname" required>
            </div>
          </div>

          <div class="col-md-3">
            <div class="mb-3">
              <label for="username" class="form-label">Korisničko ime</label>
              <input type="text" id="username" class="form-control" v-model="user.username" required>
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col-md-3">
            <div class="mb-3">
              <label for="password" class="form-label">Lozinka</label>
              <input type="password" id="password" class="form-control" v-model="user.password" required>
            </div>
          </div>

          <div class="col-md-3">
            <div class="mb-3">
              <label for="confirmPassword" class="form-label">Potvrdi lozinku</label>
              <input type="password" id="confirmPassword" class="form-control" v-model="confirmPassword" required>
              <div v-if="passwordsMismatch" class="text-danger">Lozinke se ne podudaraju.</div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="row">
      <div class="position-relative text-start">
        <button type="submit" class="btn btn-success mt-1" @click="handleSubmit" :disabled="isSaveDisabled">Spasi</button>
      </div>

      <div class="d-flex align-items-center">
        <div v-if="errorMessage" class="alert alert-danger alert-dismissible mt-3" style="float: right;">
          {{ errorMessage }}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div v-if="successMessage" class="alert alert-success alert-dismissible mt-3"
          style="max-width: 50%; float: right;">
          {{ successMessage }}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>
    </div>
  </div>
  <br><br><br>
</template>


<script>
import axios from 'axios';
import config from '../config';

const apiUrl = `${config.backendUrl}/users`;

export default {
  data() {
    return {
      user: {
        username: '',
        password: '',
        placeId: 0,
        state: '',
        name: '',
        lastname: '',
      },
      confirmPassword: '',
      successMessage: '',
      errorMessage: '',

    };
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      const token = localStorage.getItem('jwt');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      axios.post(apiUrl, this.user, { headers })
        .then(response => {
          // Handle success response
          this.successMessage = "Dodan novi korisnik";
          this.$emit("submit-success");
          console.log(response.status);
        })
        .catch(error => {
          this.errorMessage = "Greska prilikom dodavanja korisnika, error=" + error;
          this.$emit("submit-error");
        });
    },


    isPasswordStrong() {
      // Implement your own password strength check logic here
      const password = this.user.password;
      return password.length >= 8; // Example: Password length of at least 8 characters
    },
  },
  computed: {
    passwordsMismatch() {
      return this.user.password !== this.confirmPassword;
    },
    isSaveDisabled() {
      return (
        !this.user.password || !this.user.username || !this.user.name || !this.user.lastname ||
        !this.isPasswordStrong() ||
        this.user.password !== this.confirmPassword
      );
    },
  },


};
</script>

  
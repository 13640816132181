import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from './components/UserLogin.vue';
import Protected from './components/PageProtected.vue';
import NewCase from "./components/NewCase.vue";
import SlucajList from './components/SlucajList.vue';
import SlucajEdit from './components/SlucajEdit.vue';
import LookupManagment from './components/LookupManagment.vue'; // Import the LookupManagment component
import NotFound from './components/NotFound.vue'; // Import the NotFound component
import UserPage from './components/UserPage.vue';
import UserPageEdit from './components/UserPageEdit.vue';
import UserList from './components/UsersList.vue';
import ChildPageEdit from './components/ChildPageEdit.vue';
import ChildPageList from './components/ChildPageList.vue';
import ChildPage from './components/ChildPage.vue';
import PersonOfTrust from './components/PersonOfTrust.vue'
import PersonOfTrustEdit from './components/PersonOfTrustEdit.vue'
import PersonOfTrustList from './components/PersonOfTrustList.vue'

import HelpProvided from './components/HelpProvided.vue'
import HelpProvidedEdit from './components/HelpProvidedEdit.vue'
import HelpProvidedList from './components/HelpProvidedList.vue'
import HelpProvidedKids from './components/HelpProvidedKids.vue'
import HelpProvidedKidsEdit from './components/HelpProvidedKidsEdit.vue'


//reports
import ZrtveReport from './components/Reports/ZrtveReport.vue'
import ZrtveBrojDanaReport from './components/Reports/ZrtveReportBrojDana.vue'
import ZrtvaSumReport from './components/Reports/ZrtvaSumReport.vue'
import PruzeneUslugeReport from './components/Reports/PruzeneUsluge.vue'
import VrsteMjereReport from './components/Reports/VrsteMjere.vue'
import BracniStatusReport from './components/Reports/BracniStatus.vue'
import RelacijaSaPociniocomReport from './components/Reports/RelacijaSaPociniocom.vue'
import StambeniStatusReport from './components/Reports/StambeniStatus.vue'
import NivoObrazovanjaReport from './components/Reports/NivoObrazovanja.vue'
import MentalnoOboljenjeReport from './components/Reports/MentalnoOboljenje.vue'
import PosjedujeOruzijeReport from './components/Reports/PosjedujeOruzije.vue'
import RizikReport from './components/Reports/RizikReport.vue'
import TrajanjeNasiljaReport from './components/Reports/TrajanjaNasilja.vue'
import VrstaNasiljaReport from './components/Reports/VrstaNasilja.vue'
import UzrokNasiljaReport from './components/Reports/UzrokNasilja.vue'
import DobKorisnikaReport from './components/Reports/DobKorisnika.vue'
import PruzeneUslugePoKorisniku from './components/Reports/PruzeneUslugePoKorisniku.vue'


const routes = [
    { path: '/', component: UserLogin },
    { path: '/login', component: UserLogin },
    { path: '/protected', component: Protected, meta: { requiresAuth: true } },
    { path: "/new-case", name: "NewCase", component: NewCase, meta: { requiresAuth: true } },
    { path: '/slucaj-list', name: 'SlucajList', component: SlucajList, meta: { requiresAuth: true } },
    { path: '/slucaj/edit/:slucajId', name: 'SlucajEdit', component: SlucajEdit, meta: { requiresAuth: true } },
    { path: '/user', name: 'UserPage', component: UserPage, meta: { requiresAuth: true } },
    { path: '/person_of_trust/edit/:personID', name: 'PersonOfTrustEdit', component: PersonOfTrustEdit, meta: { requiresAuth: true } },
    { path: '/service/edit/:helpProvidedID', name: 'HelpProvidedEdit', component: HelpProvidedEdit, meta: { requiresAuth: true } },

    { path: '/child-list', name: 'ChildPageList', component: ChildPageList, meta: { requiresAuth: true } },
    { path: '/child/edit/:childID', name: 'ChildPageEdit', component: ChildPageEdit, meta: { requiresAuth: true } },
    { path: '/child-create', name: 'ChildPage', component: ChildPage, meta: { requiresAuth: true } },
    { path: '/person_of_trust', name: 'PersonOfTrust', component: PersonOfTrust, meta: { requiresAuth: true } },
    { path: '/person_of_trust_view', name: 'PersonOfTrustList', component: PersonOfTrustList, meta: { requiresAuth: true } },
    { path: '/service', name: 'HelpProvided', component: HelpProvided, meta: { requiresAuth: true } },
    { path: '/service_kids', name: 'HelpProvidedKids', component: HelpProvidedKids, meta: { requiresAuth: true } },
    { path: '/service_kids/edit/:helpProvidedID', name: 'HelpProvidedKidsEdit', component: HelpProvidedKidsEdit, meta: { requiresAuth: true } },

    { path: '/service/list', name: 'HelpProvidedList', component: HelpProvidedList, meta: { requiresAuth: true } },
    { path: '/user/list', name: 'UserList', component: UserList, meta: { requiresAuth: true } },
    { path: '/user/edit/:username', name: 'UserPageEdit', component: UserPageEdit, meta: { requiresAuth: true } },
    { path: '/lookup-management/:lookupName', name: 'LookupManagment', component: LookupManagment, meta: { requiresAuth: true } },
    { path: '/reports/zrtve', name: 'ZrtveReport', component: ZrtveReport, meta: { requiresAuth: true } },
    { path: '/reports/zrtve_v2', name: 'ZrtveBrojDanaReport', component: ZrtveBrojDanaReport, meta: { requiresAuth: true } },
    { path: '/reports/zrtve_v4', name: 'ZrtvaSumReport', component: ZrtvaSumReport, meta: { requiresAuth: true } },

    { path: '/reports/usluge', name: 'PruzeneUslugeReport', component: PruzeneUslugeReport, meta: { requiresAuth: true } },
    { path: '/reports/usluge_po_korisniku', name: 'PruzeneUslugePoKorisnikuReport', component: PruzeneUslugePoKorisniku, meta: { requiresAuth: true } },
    { path: '/reports/vrsta_mjere', name: 'VrsteMjereReport', component: VrsteMjereReport, meta: { requiresAuth: true } },
    { path: '/reports/bracni_status', name: 'BracniStatusReport', component: BracniStatusReport, meta: { requiresAuth: true } },
    { path: '/reports/relacija_sa_pocinicom', name: 'RelacijaSaPociniocomReport', component: RelacijaSaPociniocomReport, meta: { requiresAuth: true } },
    { path: '/reports/stambeni_status', name: 'StambeniStatusReport', component: StambeniStatusReport, meta: { requiresAuth: true } },
    { path: '/reports/obrazovanje', name: 'NivoObrazovanjaReport', component: NivoObrazovanjaReport, meta: { requiresAuth: true } },
    { path: '/reports/mentalno', name: 'MentalnoOboljenjeReport', component: MentalnoOboljenjeReport, meta: { requiresAuth: true } },
    { path: '/reports/oruzije', name: 'PosjedujeOruzijeReport', component: PosjedujeOruzijeReport, meta: { requiresAuth: true } },
    { path: '/reports/rizik', name: 'RizikReport', component: RizikReport, meta: { requiresAuth: true } },
    { path: '/reports/trajanje_nasilja', name: 'TrajanjeNasiljaReport', component: TrajanjeNasiljaReport, meta: { requiresAuth: true } },
    { path: '/reports/vrsta_nasilja', name: 'VrstaNasiljaReport', component: VrstaNasiljaReport, meta: { requiresAuth: true } },
    { path: '/reports/uzrok_nasilja', name: 'UzrokNasiljaReport', component: UzrokNasiljaReport, meta: { requiresAuth: true } },
    { path: '/reports/dob_korisnika', name: 'DobKorisnikaReport', component: DobKorisnikaReport, meta: { requiresAuth: true } },
    { path: '/not-found', name: 'notFound', component: NotFound }, 
    { path: '/:catchAll(.*)', redirect: { name: 'notFound' } },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // Check if the route requires authentication and is not the health check route
    if (to.matched.some(record => record.meta.requiresAuth) && to.path !== '/') {
        const token = localStorage.getItem('jwt');
        if (!token) {
            next({ path: '/login' });
        } else {
            const payload = token.split('.')[1];
            const decodedPayload = atob(payload);
            const { exp } = JSON.parse(decodedPayload);
            const now = Math.floor(Date.now() / 1000);
            if (exp < now) {
                next({ path: '/login' });
            } else {
                next();
            }
        }
    } else {
        next();
    }
});


export default router;

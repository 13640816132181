<template>
    <div>
      <person-of-trust :person="person" :editMode="true"  />
    </div>
  </template>
  
  <script>
  import PersonOfTrust from './PersonOfTrust.vue';
  
  export default {
    name: 'PersonOfTrustEdit',
    components: {
      PersonOfTrust,
    },
    data() {
      return {
        editMode: true,
            personTrust: {
                Ime: '',
                Prezime: '',
                DatumPruzanjaPomoci: null,
                Institucija: null,
                FazaPostupka: null,
                OpisPomoci: null,
            },
            InstitucijaList: [],
            FazaPostupkaList: [],
            successMessage: '',
            slucajList: [],
            id: null,
      };
    },
  
  };
  </script>
  
<template>
    <div>
      <help-provided :editMode="true"  />
    </div>
  </template>
  
  <script>
  import HelpProvided from './HelpProvidedKids.vue';
  
  export default {
    name: 'HelpProvidedKidsEdit',
    components: {
      HelpProvided,
    },
    data() {
        return {
            kidmode: false,
            editMode: true,
            helpProvided: {
                DatumPruzanjaPomoci: null,
                DatumDolaska: null,
                DatumOdlaska: null,
                TipPomoci: '',
                Komentar: null,
                SlucajID: null,
                ChildID: null,

            },
            TipPomociList: [],
            successMessage: '',
            slucajList: [],
            TipPomociListArray: [],
            childrenList: [],

            id: null,
        };
    },
  
  };
  </script>
  
<template>
    <div>
        <h2 class="text-start">Pregled lica od povjerenja</h2>
        <div class="mb-3">
            <input type="text" class="form-control" placeholder="Search..." v-model="searchQuery" />
        </div>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Uredi</th>
                    <th>Lice od povjerenja</th>
                    <th>Zrtva</th>
                    <th>Datum Pružanja Pomoci</th>
                    <th>Institucija</th>
                    <th>Faza Postupka</th>
                    <th>Datum kreiranja</th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="person in filteredPersonList" :key="person.ID" @click="goToPersonOfTrustEdit(person)">
                    <td style="width: 1%"><i class="fa-solid fa-user-pen"></i></td>
                    <td style="width: 10%">{{ person.Ime + ' ' + person.Prezime }}</td>
                    <td style="width: 10%">{{ person.Zrtva }}</td>
                    <td style="width: 10%">{{ formatDate(person.DatumPruzanjaPomoci) }}</td>
                    <td style="width: 10%">{{ person.InstitucijaString }}</td>
                    <td style="width: 10%">{{ person.FazaPostupkaString }}</td>
                    <td style="width: 10%">{{ formatDate(person.DateCreated) }}</td>

                </tr>
                <tr v-if="filteredPersonList.length === 0">
                    <td colspan="12" class="text-center">Nema podataka</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script>
import axios from 'axios';
import config from '../config';

const apiUrl = `${config.backendUrl}/personTrust`;

export default {
    name: 'PersonOfTrustList',
    data() {
        return {
            personList: [],
            searchQuery: '',
        };
    },
    async mounted() {
        try {
            const token = localStorage.getItem('jwt');
            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            this.personList = response.data;
        } catch (error) {
            console.error('Error fetching Person of Trust list:', error);
        }
    },
    computed: {
        filteredPersonList() {
            const query = this.searchQuery.toLowerCase().trim();
            if (!query) {
                return this.personList;
            }

            const searchTerms = query.split(' ');

            return this.personList.filter(person => {
                const firstName = person.Ime ? person.Ime.toLowerCase() : '';
                const lastName = person.Prezime ? person.Prezime.toLowerCase() : '';

                // Check if each search term is present in any searchable field
                return searchTerms.every(term =>
                    firstName.includes(term) ||
                    lastName.includes(term) ||
                    (person.Zrtva && person.Zrtva.toLowerCase().includes(term)) ||
                    (person.DatumPruzanjaPomoci && person.DatumPruzanjaPomoci.includes(term)) ||
                    (person.InstitucijaString && person.InstitucijaString.toLowerCase().includes(term)) ||
                    (person.FazaPostupkaString && person.FazaPostupkaString.toLowerCase().includes(term)) ||
                    (person.OpisPomoci && person.OpisPomoci.toLowerCase().includes(term))
                );
            });
        }

    },
    methods: {
        goToPersonOfTrustEdit(person) {
            this.$router.push({ name: 'PersonOfTrustEdit', params: { personID: person.ID } });
        },
        formatDate(date) {
            if (date) {
                let parsedDate = new Date(date);
                if (!isNaN(parsedDate)) {
                    return parsedDate.toISOString().slice(0, 10);
                }
            }
            return ''; // or return a placeholder string like 'N/A' or 'Unknown'
        },
    },
};
</script>
<style>
tbody tr:hover {
    cursor: pointer;
    background-color: #f5f5f5;
}
</style>
<template>
  <router-link
    :to="to"
    :class="linkClasses"
    active-class="active"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <i :class="iconClasses" :style="{ color: iconColor }"></i>
    <span>{{ label }}</span>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    iconColor: {
      type: String,
      default: "#FFFFF"
    },
    animation: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isHovered: false
    };
  },
  computed: {
    linkClasses() {
      return [
        "list-group-item",
        "list-group-item-action",
        "py-2",
        "ripple",
        { active: this.$route.path === this.to }
      ];
    },
    iconClasses() {
      return [
        "fa-regular",
        this.icon,
        //this.animation,
        { "fa-fade": this.isHovered && this.animation === "fa-fade"  },
        { "fa-spin": this.isHovered && this.animation === "fa-spin"  },
        { "fa-flip": this.isHovered && this.animation === "fa-flip"  },
        { "fa-beat": this.isHovered && this.animation === "fa-beat"  },

        { "fa-bounce": this.isHovered },

      ];
    },
    computedKidsMode() {
    // Determine the condition based on 'to' prop
    return this.to === 'specificString';
  },
  },
  methods: {
    handleMouseOver() {
      this.isHovered = true;
    },
    handleMouseLeave() {
      this.isHovered = false;
    }
  }
};
</script>

<template>
    <div class="position-relative " style="padding-left: 1%;">
      
    <form @submit="submitForm">
      <div class="row " >
      <div class="col-4">
        <h1 class="position-relative"> Šifarnik  {{ newLookup.name }}</h1>
        <hr class="hr hr-blurry" />

        
        <br>
        <br>

        <div class="mb-4" v-show="false">
          <label for="name">Name</label>
          <div class="form-control">{{ newLookup.name }}</div>
        </div> 

        <div class="mb-3">
            <label for="Name" class="form-label">Šifarnik</label>
            <select class="form-control" id="Name" v-model="selectedLookup">
                <option disabled value="">Odaberi sifarnik</option>
                <option v-for="dropdown in lookupListDistinct" :key="dropdown"
                    :value="dropdown">{{ dropdown }}</option>
            </select>
        </div>
        <div class="mb-3 d-flex align-items-center">
          <input type="text" id="value" v-model="newLookup.value" class="form-control me-2" placeholder="Unesite novu vrijednost za šifarnik">
          <button type="submit" class="btn btn-success mt-1"><i class="fa-solid fa-file-circle-plus"></i></button>
        </div>
        <br>
      </div>
      <div class="col-6">
        <br><br><br><br>
        
        <div class="d-flex  align-items-center">
                        <div v-if="errorMessage" class="alert alert-danger alert-dismissible mt-3" style="max-width: 50%;">
                            <br>
                            {{ errorMessage }}
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                        <div v-if="successMessage" class="alert alert-success alert-dismissible mt-3" style="max-width: 50%;">
                            {{ successMessage }}
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
          </div>
      </div>
    </div>
      
    </form>

    <div class="col-md-4 "> 
      <!-- <div class="col-md-4 text-start"> -->

      <table class="table table-striped mt-3">
          <thead>
            <tr>
              <th>Vrijednosti
                <div class="border-top border-secondary my-4"></div>

              </th>
              
            </tr>

          </thead>
          <tbody>
            <template v-if="lookupList.length > 0">
              <tr v-for="lookup in lookupList" :key="lookup.id">
                <td>{{ lookup.value }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="1" class="text-center">Odaberite sifarnik</td>
              </tr>
            </template>
          </tbody>
      </table>
      </div>


  </div>
</template>

<script>
import axios from 'axios';
import config from '../config';


export default {

  data() {
    return {
      newLookup: {
        name: '',
        value: '',
      },
      lookupList: [],
      lookupListDistinct: [],
      errorMessage: '',
      successMessage: '',
    };
  },

  beforeRouteUpdate(to, from, next) {
    this.newLookup.name = to.params.lookupName;
    this.fetchLookupData().then(() => {
      next();
    });
  },

  async mounted() {
      try {
        
        if(this.$route.params.lookupName){
          this.newLookup.name = this.$route.params.lookupName;
          const token = localStorage.getItem('jwt');
          const headers = {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            };
          //this.newLookup.name='zavisnost'
          if(this.newLookup.name != ' '){

          
          const apiUrl = `${config.backendUrl}/lookup?name=`+this.newLookup.name;
          var response = await axios.get(apiUrl, {headers});
          this.lookupList = response.data;
          response.data=null;
          response = await axios.get(`${config.backendUrl}/lookup/sifarnici`, {headers});
          this.lookupListDistinct = response.data
          console.log(response.data)
          }
        }
      
      } catch (error) {
        console.error('Error fetching slucaj list:', error);
      }
      
    },
  methods: {
    submitForm(event) {
      event.preventDefault();
      const token = localStorage.getItem('jwt');
      const apiUrl = `${config.backendUrl}/lookup`;
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      axios.post(apiUrl, this.newLookup)
        .then(response => {
          this.newLookup.value = '';
        //  this.newLookup.name = this.newLookup.name;
        this.successMessage = 'Dodana nova vrijednost u sifarnik';

        this.fetchLookupData();
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
          this.errorMessage = 'Vrijednost vec posotji u sifarniku!';

        });
    },

    async fetchLookupData() {
      try {
        const token = localStorage.getItem('jwt');
        const apiUrl = `${config.backendUrl}/lookup?name=${this.newLookup.name}`;
        const response = await axios.get(apiUrl, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        this.lookupList = response.data;
      } catch (error) {
        console.error('Error fetching lookup data:', error);
      }
    },
  },
  computed:{
    selectedLookup: {
      get() {
        return this.lookupListDistinct.find(item => item === this.newLookup.name);
      },
        set(value) {
          this.newLookup.name = value;
        },
      },

  },
    watch: {
      selectedLookup(newVal) {
        this.fetchLookupData(newVal);
      }
    },
};
</script>

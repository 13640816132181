<template>
  <header id="header" style="background-color: #F8F9FA;">
    <h5 v-if="showNavigation"> <i class="fa-solid fa-hands-holding-child"></i> Fondacija Lokalne Demokratije <i class="fa-solid fa-hands-holding-child"></i></h5>
    <br><br>
  </header>
<div id="body">

  <div class="row">
    <div v-if="showNavigation" class="col-lg-2 col-md-3 col-sm-4 col-12" style="max-width: 250px" id="menuBar">
      <AppNavbar v-if="isLoggedIn"></AppNavbar>
    </div>
    <div :class="{'col-lg-10 col-md-9 col-sm-8 col-12': showNavigation}" style="padding-left: 3%; border-radius: 25px">
      <br>
      <router-view></router-view>
    </div>
  </div>
</div>
</template>
<script>

import AppNavbar from '../src/components/AppNavbar.vue';


export default {
  name: 'App',
  components: {
    AppNavbar,
  },
  computed: {
    showNavigation() {
      return this.$route.path !== '/login' &&  this.$route.path !== '/' ;
    },
    isLoggedIn() {
      const isUserLoggedIn = !!localStorage.getItem('jwt');
      const isLoginPage = this.$route.path === '/login';
      const isNotFound = this.$route.name === 'notFound';
      return isUserLoggedIn && !isLoginPage && !isNotFound;
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  background-color: rgb(166, 160, 160);

}

#body{
  background-color: #F8F9FA;
}
.custom-dashboard {
  background-color: #ffffff;
  /* #86c0abad */
  border-top: 0px solid #0091b5;
  border-left-color: #fff;
  border-right-color: #fff;
  color: #000000;
}

header h5 {
  font-size: 30px;
  font-weight: 600;
  background-image: linear-gradient(45deg, #553c9a, #ee4b2b);
  background-color: #c70000;

  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  padding-left: 15%;
}



h2 {
  font-size: 30px;
  font-weight: 600;
  background-image: linear-gradient(45deg, #553c9a, #ee4b2b);

  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}


</style>

<template>
    <div>
      <!-- Delete Button -->
      <button style="margin-top: 3px;" class="btn btn-danger" @click="openDeleteConfirmationModal">
        Obrisi
      </button>
  
      <!-- Delete Confirmation Modal -->
      <div class="modal fade" id="deleteConfirmationModal" tabindex="-1" role="dialog"
           aria-labelledby="deleteConfirmationModalLabel" aria-hidden="true">
        <!-- Modal content -->
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteConfirmationModalLabel">Potvrda o brisanju</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Odustani" @click="closeDeleteConfirmationModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Da li ste sigurni da želite obrisati?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeDeleteConfirmationModal">
                Odustani
              </button>
              <button type="button" class="btn btn-danger" @click="confirmDelete">
                Obrisi
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      openDeleteConfirmationModal() {
        const modal = document.getElementById('deleteConfirmationModal');
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
      },
      closeDeleteConfirmationModal() {
        const modal = document.getElementById('deleteConfirmationModal');
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
      },
      confirmDelete() {
        this.$emit('delete');
        this.closeDeleteConfirmationModal();
      }
    }
  };
  </script>
  
  <style>

.modal-dialog.modal-dialog-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 1rem);
}
  </style>
  
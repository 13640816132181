<template>
    <div>
        <h2 class="text-start">Pregled pruženih usluga</h2>
        <div class="mb-3">
            <input type="text" class="form-control" placeholder="Search..." v-model="searchQuery" />
        </div>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Uredi</th>
                    <th>Korisnik</th>
                    <th>Datum Pružanja Pomoci</th>
                    <!-- <th>Tip pomoci</th> -->
                    <th>Datum kreiranja</th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="service in filteredserviceList" :key="service.ID" @click="goToserviceOfTrustEdit(service)">
                    <td style="width: 1%"><i class="fa-solid fa-user-pen"></i></td>
                    <td style="width: 10%">{{ service.Zrtva }}</td>
                    <td style="width: 10%">{{ formatDate(service.DatumPruzanjaPomoci) }}</td>
                    <!-- <td style="width: 10%">{{ service.TipPomociOpisno }}</td> -->
                    <td style="width: 10%">{{ formatDate(service.DateCreated) }}</td>

                </tr>
                <tr v-if="filteredserviceList.length === 0">
                    <td colspan="12" class="text-center">Nema podataka</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script>
import axios from 'axios';
import config from '../config';

const apiUrl = `${config.backendUrl}/service`;

export default {
    name: 'serviceOfTrustList',
    data() {
        return {
            serviceList: [],
            searchQuery: '',
        };
    },
    async mounted() {
        try {
            const token = localStorage.getItem('jwt');
            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            this.serviceList = response.data;
        } catch (error) {
            console.error('Error fetching service of Trust list:', error);
        }
    },
    computed: {
        filteredserviceList() {
            const query = this.searchQuery.toLowerCase().trim();
            if (!query) {
                return this.serviceList;
            }

            const searchTerms = query.split(' ');

            return this.serviceList.filter(service => {
                const firstName = service.Ime ? service.Ime.toLowerCase() : '';
                const lastName = service.Prezime ? service.Prezime.toLowerCase() : '';

                // Check if each search term is present in any searchable field
                return searchTerms.every(term =>
                    firstName.includes(term) ||
                    lastName.includes(term) ||
                    (service.Zrtva && service.Zrtva.toLowerCase().includes(term)) ||
                    (service.DatumPruzanjaPomoci && service.DatumPruzanjaPomoci.includes(term)) ||
                    (service.DatumOdlaska && service.DatumOdlaska.includes(term)) ||
                    (service.DatumDolaska && service.DatumDolaska.includes(term)) ||
                    (service.TipPomociOpisno && service.TipPomociOpisno.toLowerCase().includes(term)) ||
                    (service.DateCreated && service.DateCreated.includes(term))
                );
            });
        }

    },
    methods: {
        // goToserviceOfTrustEdit(service) {
        //     this.$router.push({ name: 'HelpProvidedEdit', params: { helpProvidedID: service.ID } });

        // },
        goToserviceOfTrustEdit(service) {
            if (service.ChildID !== null) {
                this.$router.push({ name: 'HelpProvidedKidsEdit', params: { helpProvidedID: service.ID, childId: service.ChildID } });
            } else {
                this.$router.push({ name: 'HelpProvidedEdit', params: { helpProvidedID: service.ID } });
            }
        },

        formatDate(date) {
            if (date) {
                let parsedDate = new Date(date);
                if (!isNaN(parsedDate)) {
                    return parsedDate.toISOString().slice(0, 10);
                }
            }
            return ''; // or return a placeholder string like 'N/A' or 'Unknown'
        },
    },
};
</script>
<style>
tbody tr:hover {
    cursor: pointer;
    background-color: #f5f5f5;
}
</style>
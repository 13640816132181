<template>
    <div>

        <child-form :form-mode="'edit'" :initial-child="child" :edit-mode="true" @submit-success="handleSuccess" />

    </div>
</template>
  
<script>
import ChildForm from "./ChildPage.vue";

export default{
    components: {
        ChildForm,
    },
    data() {
        return {
            child: {
                Ime: '',
                Prezime: '',
                Adresa: '',
                DatumRodenja: null,
                MjestoRodjenja: '',
                JMBG: '',
                DijetePoRedu: null,
                DobDjetata: '',
                DijetePrhodalo: null,
                DjeteProgovrilo: null,
                DijeteProblemi: null,
                dijeteUsloviZaZivot: '',
                dijeteCestoBolesno: null,
                dijeteSklonoPovredjivanju: null,
                dobDjetetaPrveNesuglasice: '',
                dijetePrisutnoNasilju: null,
                dijeteSkola: null,
                dijeteZavrseniRazredUspijeg: null,
                dijeteNapustaloSkolu: null,
                dijeteNaupostaloSkoluKomentar: '',
                dijeteZeliliNastavitiPrekinutoskolovanje: null,
                dijetePrveSvadjeRoditelja: '',
                dijeteZrtvaNasilje: null,
                dijeteVrstaNasilja: '',
                dijetePosliejdeiceNasilja: null,
                DijetePosliejdeiceNasiljaVrsta: null,
                mjereZastite: null,
                VrsteMjereZastite: null,
                trajanjeMjere: '',
                Komentar: '',
                OpisMajke: '',
                OpisOca: '',
                OpisBrataSestre: '',
                NapustanjeSklonista: '',
                DijeteVrstaNasiljaArray: [],
                DijetePosliejdeiceNasiljaVrstaArray: [],
                DijeteProblemiArray: [],
                DijeteUsloviZaZivotArray:[],
                VrsteMjereZastiteArrat:[],
                DatumUlaska: null,
                DatumOdlaska: null,
            },
            editMode: true,
        }
    },
}
</script>
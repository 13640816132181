<template>
    <div id="filter" class="row" style="padding-bottom: 1%;">
        <h2 class="text-start">Izvještaj provedenih dana u sigurnoj kući</h2>

        <div class="col-md-2">
            <br>
            <label for="isMasked" class="form-label">Sakrij ime i prezime</label>

            <select class="form-control" v-model="isMasked">
                <option :value="false">Ne</option>
                <option :value="true">Da</option>
            </select>
        </div>


        <div class="col-md-2">
            <br>
            <label for="DatumDolaska" class="form-label">Datum ulaska od</label>
            <input type="date" class="form-control" id="DatumDolaska" v-model="this.DatumDolaska">
        </div>

        <div class="col-md-2">
            <br>
            <label for="DatumOdlaska" class="form-label">Datum odlaska do</label>
            <input type="date" class="form-control" id="DatumOdlaska" v-model="this.DatumOdlaska">
        </div>


        <div class="col-md-1" style="padding-top: 2%;">
            <br>
            <button @click="printData" type="button" class="btn btn-secondary" id="print"><i class="fa-solid fa-print"></i>
                Print</button>
        </div>
    </div>
    <br>
    <br>

    <div>
        <hr id="divider" class="hr">
        <table id="tablePrint" class="table table-striped">
            <thead>
                <tr>
                    <th>Tip</th>
                    <th v-if="!isMasked">Ime</th>
                    <th v-if="!isMasked">Prezime</th>
                    <th>Datum ulaska</th>
                    <th>Datum odlaska</th>
                    <th>Broj dana u sigurnoj kuci</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in filteredReportData" :key="item.id">
                    <td>{{ item.Tip }}</td>
                    <td v-if="!isMasked">{{ item.Ime }}</td>
                    <td v-if="!isMasked">{{ item.Prezime }}</td>
                    <td>{{ formatDate(item.DatumDolaska) }}</td>
                    <td>{{ formatDate(item.DatumOdlaska) }}</td>
                    <td>{{ item.BrojDana }}</td>
                </tr>
                <tr>
                    <td colspan="4" v-if="!isMasked"><strong></strong></td>
                    <td colspan="2" v-if="isMasked"><strong></strong></td>
                    <td colspan="1"><strong>Ukupno:</strong></td>
                    <td><strong>{{ totalBrojDana }}</strong></td>

                </tr>
            </tbody>
        </table>



    </div>
</template>

<script>

import axios from 'axios';
import config from '../../config';

const apiUrl = `${config.backendUrl}/slucaj/report_v2`;

const apiUrlLookupList = `${config.backendUrl}/lookup/list`;

export default {
    data() {
        return {
            reportData: [],
            tipFilter: '',
            isMasked: false,
            DatumOdlaska: null,
            DatumDolaska: null,
            lookupData: [],
            filteredData: [],
            Tip: '',
            tipList: [{ value: 'Djevojka', label: 'Djevojka' }, { value: 'Žena', label: 'Žena' }],
            daNeList: [{ value: 'Ne', label: 'Ne' }, { value: 'Da', label: 'Da' }],


        };
    },
    mounted() {
        const token = localStorage.getItem('jwt');
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        axios.get(apiUrl, { headers: headers })
            .then(response => {
                this.reportData = response.data;
            })
            .catch(error => {
                console.error(error);
            });

        axios.get(apiUrlLookupList, { headers })
            .then(response => {
                this.lookupData = response.data.map(item => ({ label: item.value, value: item.id }));

            }).catch(error => {
                console.error('Error:', error);
            });
    },
    methods: {
        getLabelById(id) {
            const lookupItem = this.lookupData.find(item => item.value === id);
            return lookupItem ? lookupItem.label : '-';
        },
        printData() {
            window.print();
        },
        formatDate(date) {
            if (date) {
                let parsedDate = new Date(date);
                if (!isNaN(parsedDate)) {
                    return parsedDate.toISOString().slice(0, 10);
                }
            }
            return ''; // or return a placeholder string like 'N/A' or 'Unknown'
        },
    },

    computed: {
        filteredReportData() {
            return this.reportData.filter(item => {
                const itemDateStart = item.DatumDolaska ? new Date(item.DatumDolaska).setHours(0, 0, 0, 0) : null;
                const currentDate = new Date();
                const itemDateEnd = item.DatumOdlaska ? new Date(item.DatumOdlaska).setHours(0, 0, 0, 0) : currentDate;

                const selectedDateStart = this.DatumDolaska ? new Date(this.DatumDolaska).setHours(0, 0, 0, 0) : null;
                const selectedDateEnd = this.DatumOdlaska ? new Date(this.DatumOdlaska).setHours(0, 0, 0, 0) : null;

                const overlap = (!selectedDateStart && !selectedDateEnd) ||
                    ((itemDateStart && itemDateStart <= selectedDateEnd) &&
                        (itemDateEnd && itemDateEnd >= selectedDateStart));

                return overlap;
            });
        },

        totalBrojDana() {
            return this.filteredReportData.reduce((total, item) => total + item.BrojDana, 0);
        },

        selectedTip: {
            get() {
                return this.tipList.find(item => item.value === this.Tip)?.value;
            },
            set(value) {
                this.Tip = value;
            },
        },



    },

};

</script>

<style>
@media print {
    #menuBar {
        display: none;
    }

    #divider {
        display: none;
    }

    @page {
        margin-bottom: 2.3cm;
        /* adjust as needed */
    }

    #tablePrint {
        bottom: 10px;
    }

    #printButton {
        left: -9999px;
        width: 1px;
        height: 1px;
        visibility: hidden;
    }



    #header {
        display: none;

    }

    #filter {
        display: none;

    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    table {
        width: 100%;
        /* Adjust as needed */
    }

    th,
    td {
        overflow: hidden;
        padding-left: 10px;
    }
}

.custom-print-button {
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    /* Add other custom styles as needed */
}

body {
    margin: 0;
    padding: 0;
}
</style>